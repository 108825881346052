import { configureStore } from '@reduxjs/toolkit';
import { commonSlice } from './slice/CommonSlice';
import { teamSlice } from './slice/team/TeamSlice';
import { suiteSlice } from './slice/suite/SuiteSlice';
import { testSlice } from './slice/test/TestSlice';
import { environmentSlice } from './slice/environment/EnvironmentSlice';
import { variableSlice } from './slice/variable/VariableSlice';
import { productSlice } from './slice/product/ProductSlice';

const store = configureStore({
  reducer: {
    generalData: commonSlice.reducer,
    teamData: teamSlice.reducer,
    suiteData: suiteSlice.reducer,
    testData: testSlice.reducer,
    environmentData: environmentSlice.reducer,
    variableData: variableSlice.reducer,
    productData: productSlice.reducer
  }
});
export default store;
