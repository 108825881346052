import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import ConfirmDialog from '../../components/modal/ConfirmDialog';

const EnvironmentExportForm = (props) => {
  const handleCopyJson = async () => {
    try {
      await navigator.clipboard.writeText(props.jsonData);
      props.setOpenExportEnvironmentModal(false);
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
    }
  };

  const handleEnvironmentDownload = () => {
    const blob = new Blob([props.jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Environment_${props.environment.id}_${props.environment.name}.json`;
    a.click();
    URL.revokeObjectURL(url);
    props.setOpenExportEnvironmentModal(false);
  };
  return (
    <ConfirmDialog
      open={props.open}
      width={props.width}
      title={props.title}
      saveText="Download"
      cancelText="Copy"
      handleSave={handleEnvironmentDownload}
      handleClose={handleCopyJson}>
      <Grid container>
        <TextField
          sx={{ my: '0.5rem' }}
          id="environment-test"
          label="Environment as JSON"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={9}
          value={props.jsonData}
        />
      </Grid>
    </ConfirmDialog>
  );
};

export default EnvironmentExportForm;
