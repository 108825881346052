import { Grid, Paper, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { deleteEnvironment } from '../../store/slice/environment/EnvironmentActions';
import { commonActions } from '../../store/slice/CommonSlice';
import { environmentActions } from '../../store/slice/environment/EnvironmentSlice';
import { fetchAllEnvironmentsInTestSuite } from '../../store/slice/environment/EnvironmentActions';

const EnvironmentCard = (props) => {
  const environments = props.environments;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [environment, setEnvironment] = useState({});
  const onDeleteEnvironmentBtnClose = () => setOpenDeleteModal(false);
  const dispatch = useDispatch();

  const handleDelete = (event, index) => {
    event.preventDefault();
    setOpenDeleteModal(true);
    setEnvironment(environments[index]);
  };

  const onDeleteEnvironment = () => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(deleteEnvironment(environment.testSuiteId, environment.id)).then(
      () => {
        setOpenDeleteModal(false);
        dispatch(commonActions.showSuccessAlert(`Test environment ${environment.name} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(environmentActions.resetEnvironment());
        dispatch(fetchAllEnvironmentsInTestSuite(environment.testSuiteId));
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
      }
    );
  };

  return (
    <>
      {environments.length === 0 && (
        <Grid container flex={1} justifyContent="center" mt={15}>
          You have no environments!
        </Grid>
      )}
      {environments.length > 0 && (
        <Grid container className="container-style" justifyContent="flex-start">
          {environments.map((environment, index) => (
            <Grid item key={index} lg={3}>
              <Link
                style={{ textDecoration: 'none' }}
                to={`${window.location.pathname}/environments/${environment.id}`}>
                <Paper className="team-card-style" data-testid={`environment-card-index-${index}`}>
                  <Stack>
                    <Grid
                      container
                      data-testid="environment-card-title"
                      alignItems="center"
                      justifyContent="space-between">
                      {environment.name != undefined && environment.name.length >= 25 ? (
                        <Tooltip title={environment.name}>
                          <span>{environment.name.substring(0, 25)}...</span>
                        </Tooltip>
                      ) : (
                        <span>{environment.name}</span>
                      )}
                      <IconButton sx={{ mr: 0 }} onClick={(e) => handleDelete(e, index)}>
                        <img src={DeleteIcon} />
                      </IconButton>
                    </Grid>
                    <Grid className="description-style" data-testid="environment-desc-field">
                      {environment.description != undefined &&
                      environment.description.length >= 70 ? (
                        <Tooltip title={environment.description}>
                          <span>{environment.description.substring(0, 70)}...</span>
                        </Tooltip>
                      ) : (
                        <span>{environment.description}</span>
                      )}
                    </Grid>
                    <div className="team-name-footer-style" data-testid="locations-field">
                      {environment.locations.join(', ')}
                    </div>
                  </Stack>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      <ConfirmDialog
        open={openDeleteModal}
        width="xs"
        title="Delete Environment"
        handleSave={onDeleteEnvironment}
        saveText="Delete"
        handleClose={onDeleteEnvironmentBtnClose}>
        Are you sure you want to delete this environment? This cannot be undone after it is deleted.
      </ConfirmDialog>
    </>
  );
};
export default EnvironmentCard;
