import { useParams, Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  Autocomplete,
  InputAdornment,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';
import EditIcon from '../../assets/EditIcon.svg';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { fetchSuiteById } from '../../store/slice/suite/SuiteActions';
import {
  fetchAllTestsInTestSuite,
  fetchAllBrowserTestsInTestSuite
} from '../../store/slice/test/TestActions';
import { commonActions } from '../../store/slice/CommonSlice';
import AppBreadCrumb from '../../components/breadCrumbs/AppBreadCrumb';
import TestCard from '../tests/TestCard';
import TestImportForm from '../createTest/createTestForms/TestImportForm';
import EnvironmentCard from '../environment/EnvironmentCard';
import EnvironmentImportForm from '../environment/EnvironmentImportForm';
import CreateEnvironmentModal from '../environment/CreateEnvironmentModal';
import { updateSuite, deleteSuite } from '../../store/slice/suite/SuiteActions';
import CreateTestSuiteModal from './CreateTestSuiteModal';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import {
  createEnvironment,
  fetchAllEnvironmentsInTestSuite
} from '../../store/slice/environment/EnvironmentActions';
import {
  HomeBreadCrumb,
  TestSuiteBreadCrumb,
  TestSuiteDetailsBreadCrumb
} from '../../utils/BreadCrumbUtils';
import axiosInstance from '../../services/axios';
import { fetchAllProducts } from '../../store/slice/product/ProductActions';
import { TEST_TYPES } from '../../utils/TestTypes';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
function getTabDetails(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
function getSubTabDetails(index) {
  return {
    id: index,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const TestSuiteDetails = () => {
  const { suiteId } = useParams();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [indicators, setIndicators] = useState([]);
  const [nestedValue, setNestedValue] = useState(0);
  const products = useSelector((state) => state.productData.products);
  const navigate = useNavigate();
  const suite = useSelector((state) => state.suiteData.suite);
  const tests = useSelector((state) => state.testData.tests);
  const browserTests = useSelector((state) => state.testData.browserTests);
  const environments = useSelector((state) => state.environmentData.environments);
  const [openCreateEnvironmentModal, setOpenCreateEnvironmentModal] = useState(false);
  const [openEditSuiteModal, setOpenEditSuiteModal] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [openDeleteSuiteModal, setOpenDeleteSuiteModal] = useState(false);
  const breadcrumbAr = [HomeBreadCrumb, TestSuiteBreadCrumb, TestSuiteDetailsBreadCrumb];
  const [inputValue, setInputValue] = useState('');
  const [openImportTestModal, setOpenImportTestModal] = useState(false);
  const [openImportEnvironmentModal, setOpenImportEnvironmentModal] = useState(false);

  const onEditSuiteBtnClick = () => {
    setOpenEditSuiteModal(true);
  };
  const onEditSuiteBtnClose = () => {
    setOpenEditSuiteModal(false);
  };
  const onDeleteSuiteBtnClick = () => {
    setOpenDeleteSuiteModal(true);
  };
  const onDeleteSuiteBtnClose = () => {
    setOpenDeleteSuiteModal(false);
  };

  const onCreateEnvironmentBtnClick = () => {
    setOpenCreateEnvironmentModal(true);
  };
  const onCreateEnvironmentBtnClose = () => {
    setOpenCreateEnvironmentModal(false);
  };

  const onSaveTestSuite = (testSuiteData) => {
    testSuiteData.id = suiteId;
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(updateSuite(suiteId, testSuiteData)).then(
      () => {
        setOpenEditSuiteModal(false);
        dispatch(fetchSuiteById(suiteId));
        dispatch(commonActions.showSuccessAlert(`Test Suite ${testSuiteData.name} updated`));
        dispatch(commonActions.showLoadingIcon(false));
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        setOpenEditSuiteModal(false);
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  const onDeleteSuite = () => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(deleteSuite(suiteId)).then(
      () => {
        setOpenDeleteSuiteModal(false);
        dispatch(commonActions.showSuccessAlert(`Test Suite with id ${suiteId} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        navigate('/');
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        setOpenDeleteSuiteModal(false);
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  const onSaveEnvironment = (environmentData) => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(createEnvironment(suiteId, environmentData)).then(
      () => {
        setOpenCreateEnvironmentModal(false);
        dispatch(commonActions.showSuccessAlert(`Environment ${environmentData.name} added`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(fetchAllEnvironmentsInTestSuite(suiteId));
        setValue(1);
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  useEffect(() => {
    dispatch(fetchSuiteById(suiteId));
    dispatch(fetchAllTestsInTestSuite(suiteId));
    dispatch(fetchAllBrowserTestsInTestSuite(suiteId));
    dispatch(fetchAllEnvironmentsInTestSuite(suiteId));
    dispatch(fetchAllProducts());
  }, []);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleNestedChange = (_, newValue) => {
    setNestedValue(newValue);
    setSearchResult([]);
    setInputValue('');
  };

  const handleImportTest = () => {
    setOpenImportTestModal(true);
  };

  const handleImportEnvironment = () => {
    setOpenImportEnvironmentModal(true);
  };

  useEffect(() => {
    const fetchDataForTests = async (test, isBrowserTest) => {
      try {
        const response = await axiosInstance({
          method: 'get',
          url: `/v1/test_suites/${test.testSuiteId}/${isBrowserTest ? 'browser_tests' : 'tests'}/${
            test.id
          }/results/concise?resultsCount=20`
        });
        const temp = response.data.map((data) => data.result);
        const found = indicators.some((el) => el.id === test.id);
        if (!found) {
          setIndicators((prev) => [...prev, { id: test.id, result: temp }]);
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    //Todo: check why async call for indicator is stopping click operations and remove condition for Migration Test Suite
    const migrationSuiteList = ['DataDog Migration Test Suite', 'DataDog Migration NSCP'];
    if (!migrationSuiteList.includes(suite.name)) {
      browserTests.forEach((browserTest) => {
        fetchDataForTests(browserTest, true);
      });

      tests.forEach((test) => {
        fetchDataForTests(test, false);
      });
    }
  }, [tests, browserTests]);

  return (
    <Grid container>
      <AppBreadCrumb data={breadcrumbAr} />
      <Grid item xs={10.5}>
        <Stack direction="row-reverse">
          <Button
            variant="outlined"
            onClick={onCreateEnvironmentBtnClick}
            data-testid="button-create-environment">
            Create Environment
          </Button>
          <FormControl sx={{ minWidth: '6rem', mr: '1rem' }}>
            <Select
              data-testid="dropdown-create-test"
              displayEmpty
              sx={{
                '& .MuiSelect-select': {
                  py: '7px',
                  px: '25px',
                  fontSize: '0.875rem'
                },
                '& .MuiSelect-select:hover': {
                  backgroundColor: '#00243D',
                  color: 'white'
                }
              }}
              renderValue={() => {
                return 'CREATE TEST';
              }}
              IconComponent={() => null}
              MenuProps={{
                MenuListProps: { sx: { py: '0px' } }
              }}>
              <Link
                style={{ textDecoration: 'none', color: 'black' }}
                to={`/suites/${suiteId}/${TEST_TYPES.API}`}>
                <MenuItem value={'API Test'}>API Test</MenuItem>
              </Link>
              <Link
                style={{ textDecoration: 'none', color: 'black' }}
                to={`/suites/${suiteId}/${TEST_TYPES.BROWSER}`}>
                <MenuItem value={'Browser Test'}>Browser Test</MenuItem>
              </Link>
            </Select>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item sx={{ width: '80rem', m: 'auto' }}>
        <Grid className="label">TEST SUITE</Grid>
        <Grid className="heading_team" data-testid="test-suite-name">
          {suite.name}
        </Grid>
        <Grid className="description" data-testid="test-suite-desc">
          {suite.description}
        </Grid>
        <Grid mt="1rem">Team</Grid>
        <Grid mt={0} className="description" data-testid="test-suite-team-name">
          {suite.teamName}
        </Grid>
        <Grid
          container
          className="description"
          sx={{ my: '1.5rem', display: 'flex', flexWrap: 'nowrap' }}>
          <Button
            variant="text"
            data-testid="edit-suite-button"
            onClick={onEditSuiteBtnClick}
            sx={{ minWidth: 0 }}>
            <img src={EditIcon} />
            Edit
          </Button>
          <Button variant="text" data-testid="delete-suite-button" onClick={onDeleteSuiteBtnClick}>
            <img src={DeleteIcon} />
            Delete
          </Button>
          {value === 0 && (
            <Autocomplete
              freeSolo
              sx={{ ml: '55rem', width: 220 }}
              id="search-by-test-name"
              options={
                nestedValue === 0
                  ? tests.map((option) => option.name)
                  : browserTests.map((option) => option.name)
              }
              inputValue={inputValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Test"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              onInputChange={(_event, newValue) => {
                const searchedTest =
                  nestedValue === 0
                    ? [tests.find((test) => test.name === newValue)]
                    : [browserTests.find((browserTest) => browserTest.name === newValue)];
                setSearchResult(searchedTest);
                setInputValue(newValue);
              }}
            />
          )}
        </Grid>
      </Grid>
      <TestImportForm
        open={openImportTestModal}
        width="xs"
        title="Import Test"
        setOpenImportTestModal={setOpenImportTestModal}
      />
      <CreateTestSuiteModal
        open={openEditSuiteModal}
        width="xs"
        title="Edit Test Suite"
        handleSave={onSaveTestSuite}
        handleClose={onEditSuiteBtnClose}
        suite={suite}
        teams={[]}
        products={products}
      />
      <EnvironmentImportForm
        open={openImportEnvironmentModal}
        width="xs"
        title="Import Environment"
        setOpenImportEnvironmentModal={setOpenImportEnvironmentModal}
      />
      <CreateEnvironmentModal
        open={openCreateEnvironmentModal}
        width="xs"
        title="Create New Environment"
        handleSave={onSaveEnvironment}
        handleClose={onCreateEnvironmentBtnClose}
      />
      <ConfirmDialog
        open={openDeleteSuiteModal}
        width="xs"
        title="Delete Test Suite"
        handleSave={onDeleteSuite}
        saveText="Delete"
        handleClose={onDeleteSuiteBtnClose}>
        Are you sure you want to delete this test suite? This cannot be undone after it is deleted.
      </ConfirmDialog>
      <Box
        sx={{
          width: '80rem',
          mt: '20px',
          margin: 'auto',
          minHeight: '44vh',
          '& .css-19kzrtu': { p: 0 }
        }}>
        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="tab-panels">
            <Tab label="Tests" {...getTabDetails(0)} />
            <Tab label="Environments" {...getTabDetails(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Tabs value={nestedValue} onChange={handleNestedChange} aria-label="sub-tab-panels">
            <Tab label="API Tests" {...getSubTabDetails(0)} />
            <Tab label="Browser Tests" {...getSubTabDetails(1)} />
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  data-testid="button-create-team"
                  style={{ margin: '8px', borderColor: '#dddddd' }}
                  onClick={handleImportTest}>
                  Import Test
                </Button>
              </Grid>
            </Grid>
          </Tabs>
          <TabPanel value={nestedValue} index={0}>
            {searchResult.length > 0 && searchResult[0] !== undefined ? (
              <TestCard type={TEST_TYPES.API} tests={searchResult} indicators={indicators} />
            ) : (
              <TestCard type={TEST_TYPES.API} tests={tests} indicators={indicators} />
            )}
          </TabPanel>
          <TabPanel value={nestedValue} index={1}>
            {searchResult.length > 0 && searchResult[0] !== undefined ? (
              <TestCard type={TEST_TYPES.BROWSER} tests={searchResult} indicators={indicators} />
            ) : (
              <TestCard type={TEST_TYPES.BROWSER} tests={browserTests} indicators={indicators} />
            )}
          </TabPanel>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                data-testid="button-create-team"
                onClick={handleImportEnvironment}
                style={{ margin: '12px', borderColor: '#dddddd' }}>
                IMPORT ENVIRONMENT
              </Button>
            </Grid>
          </Grid>
          <EnvironmentCard environments={environments} />
        </TabPanel>
      </Box>
    </Grid>
  );
};

export default TestSuiteDetails;
