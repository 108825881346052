export const ALLOWED_EMAIL_DOMAINS = [
  'maersk.com',
  'lns.maersk.com',
  'hamburgsud.com',
  'twill.net',
  'apmterminals.com',
  'kghcustoms.com',
  'alianca.com.br',
  'ptp.com.my',
  'damco.com'
];
