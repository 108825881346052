import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const StepDetailsModalWrapper = ({
  open,
  handleCancel,
  title,
  children,
  handleSubmit,
  edit,
  objectToEdit,
  disabled
}) => {
  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ background: '#F7F7F7' }}>
        <Button variant="outlined" onClick={(e) => handleCancel(e)}>
          Cancel
        </Button>
        <Button
          disabled={disabled}
          variant="contained"
          onClick={(e) => handleSubmit(e, objectToEdit)}>
          {edit ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default StepDetailsModalWrapper;
