import React, { useState } from 'react';
import { Button, Grid, Tooltip } from '@mui/material';
import CustomLabelsTable from './CustomLabelsTable';
import StepDetailsModalWrapper from './stepDetailsForm/stepDetailsForms/StepDetailsModalWrapper';
import AddCustomLabelModelContent from './AddCustomLabelModelContent';
import AddIcon from '@mui/icons-material/Add';

const AddCustomLabelsForm = ({
  setOpenCustomLabelsModal,
  openCustomLabelsModal,
  customLabels,
  setCustomLabels,
  edit,
  setEdit,
  alertWebhook,
  alertCondition,
  setAlertCondition,
  environment
}) => {
  const [labelName, setLabelName] = useState('');
  const [labelValue, setLabelValue] = useState('');
  const [objectToEdit, setObjectToEdit] = useState('');

  const handleCustomLabels = (e, customLabel, deleteEntry) => {
    const reset = () => {
      setLabelName('');
      setLabelValue('');
      setOpenCustomLabelsModal(false);
    };

    if (edit === true) {
      let copy = Object.assign({}, customLabels);
      delete copy[Object.keys(customLabel)[0]];
      setCustomLabels(copy);
      setCustomLabels((customLabels) => {
        return { ...customLabels, [labelName]: labelValue };
      });
      setEdit(false);
      setObjectToEdit({});
      setAlertCondition((alertCondition) => {
        return {
          ...alertCondition,
          customLabels: {
            ...copy,
            [labelName]: labelValue
          }
        };
      });
      reset();
    } else if (deleteEntry === true) {
      setCustomLabels((customLabels) => {
        const copy = { ...customLabels };
        delete copy[customLabel[0]];
        setAlertCondition((alertCondition) => {
          return {
            ...alertCondition,
            customLabels: { ...copy }
          };
        });
        return copy;
      });
    } else {
      setCustomLabels((customLabels) => {
        return { ...customLabels, [labelName]: labelValue };
      });
      setAlertCondition((alertCondition) => {
        return {
          ...alertCondition,
          customLabels: { ...customLabels, [labelName]: labelValue }
        };
      });
      reset();
    }
  };

  const setFormStateOnEdit = (e, key, value) => {
    setLabelName(key);
    setLabelValue(value);
    setEdit(true);
    setOpenCustomLabelsModal(true);
    setObjectToEdit({ [key]: value });
  };

  return (
    <>
      <Grid sx={{ display: 'flex', justifyContent: 'end', mb: '1rem' }}>
        <Tooltip
          title={
            !alertCondition?.type || !alertCondition?.threshold || !alertWebhook
              ? 'Enter alert conditions to add custom labels'
              : ''
          }>
          <span>
            <Button
              variant={!environment ? 'outlined' : 'text'}
              disabled={!alertCondition?.type || !alertCondition?.threshold || !alertWebhook}
              onClick={(e) => {
                e.preventDefault();
                setEdit(false);
                setOpenCustomLabelsModal(true);
              }}>
              {environment && <AddIcon />} Add Custom Label
            </Button>
          </span>
        </Tooltip>
        {openCustomLabelsModal && (
          <StepDetailsModalWrapper
            open={openCustomLabelsModal}
            edit={edit}
            objectToEdit={objectToEdit}
            title="Custom Label"
            handleSubmit={handleCustomLabels}
            handleCancel={(e) => {
              e.preventDefault();
              setOpenCustomLabelsModal(false);
            }}>
            <AddCustomLabelModelContent
              labelName={labelName}
              setLabelName={setLabelName}
              labelValue={labelValue}
              setLabelValue={setLabelValue}
            />
          </StepDetailsModalWrapper>
        )}
      </Grid>
      <CustomLabelsTable
        customLabels={customLabels}
        setFormStateOnEdit={setFormStateOnEdit}
        handleCustomLabels={handleCustomLabels}
      />
    </>
  );
};

export default AddCustomLabelsForm;
