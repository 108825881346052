import { Checkbox, Grid, Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EditIcon from '../../assets/EditIcon.svg';
import CustomDataGrid from '../../components/table/CustomDataGrid';
import DeleteIcon from '../../assets/DeleteIcon.svg';

const VariablesTable = (props) => {
  const environment = props.environment;
  const columns = [
    { field: 'id', headerName: 'ID', hide: true, minWidth: 100, flex: 1, type: 'number' },
    { field: 'name', headerName: 'Name', maxWidth: 250, minWidth: 100, flex: 1 },
    { field: 'description', headerName: 'Description', minWidth: 100, flex: 1 },
    { field: 'value', headerName: 'Value', minWidth: 100, flex: 1 },
    {
      field: 'secret',
      headerName: 'Secret',
      type: 'actions',
      width: 80,
      getActions: (params) => {
        return [
          <Tooltip key="secret" title="Indicates whether this variable data is saved securely">
            <span>
              <Checkbox disabled size="small" key="secret" checked={params.row.secret} />
            </span>
          </Tooltip>
        ];
      }
    },
    {
      field: 'edit',
      type: 'actions',
      width: 50,
      getActions: (params) => {
        return [
          <IconButton key="edit" onClick={(e) => props.onEditVariableBtnClick(e, params.row)}>
            <img src={EditIcon} />
          </IconButton>
        ];
      }
    },
    {
      field: 'delete',
      type: 'actions',
      width: 50,
      getActions: (params) => {
        return [
          <IconButton key="delete" onClick={(e) => props.onDeleteVariableBtnClick(e, params.row)}>
            <img src={DeleteIcon} />
          </IconButton>
        ];
      }
    }
  ];

  return (
    <Grid item xs={12} id="team-member-table" sx={{ marginBottom: '2rem' }}>
      {environment.variables != undefined && (
        <>
          <Grid sx={{ margin: 'auto' }} container width="90%" className="table-container-position">
            <Grid item xs={6}>
              Variables
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="text"
                sx={{ float: 'right', margin: '0px' }}
                onClick={props.onAddVariableBtnClick}>
                <AddIcon />
                Add Variable
              </Button>
            </Grid>
          </Grid>
          <CustomDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'name' }]
              }
            }}
            className="table"
            rows={environment.variables}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            autoHeight
            disableSelectionOnClick
          />
        </>
      )}
    </Grid>
  );
};
export default VariablesTable;
