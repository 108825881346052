import { Grid, Paper, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { deleteTest, deleteBrowserTest } from '../../store/slice/test/TestActions';
import { commonActions } from '../../store/slice/CommonSlice';
import {
  fetchAllTestsInTestSuite,
  fetchAllBrowserTestsInTestSuite
} from '../../store/slice/test/TestActions';
import { Link } from 'react-router-dom';
import { TEST_TYPES } from '../../utils/TestTypes';

const TestCard = (props) => {
  const type = props.type;
  const tests = props.tests;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [test, setTest] = useState({});
  const onDeleteTestBtnClose = () => setOpenDeleteModal(false);
  const dispatch = useDispatch();

  const handleDelete = (event, index) => {
    event.preventDefault();
    setOpenDeleteModal(true);
    setTest(tests[index]);
  };

  const onDeleteTest = () => {
    dispatch(commonActions.showLoadingIcon(true));
    const deleteAction =
      type === TEST_TYPES.BROWSER
        ? deleteBrowserTest(test.testSuiteId, test.id)
        : deleteTest(test.testSuiteId, test.id);
    dispatch(deleteAction).then(
      () => {
        setOpenDeleteModal(false);
        dispatch(commonActions.showSuccessAlert(`Test ${test.name} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        type === TEST_TYPES.BROWSER
          ? dispatch(fetchAllBrowserTestsInTestSuite(test.testSuiteId))
          : dispatch(fetchAllTestsInTestSuite(test.testSuiteId));
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
      }
    );
  };

  return (
    <>
      {tests.length === 0 && (
        <Grid container flex={1} justifyContent="center" mt={15}>
          You have no tests!
        </Grid>
      )}
      {tests.length > 0 && (
        <Grid container className="container-style" justifyContent="flex-start">
          {tests.map((test, index) => (
            <Grid item key={index} lg={3}>
              <Link
                style={{ textDecoration: 'none' }}
                to={
                  type === TEST_TYPES.API
                    ? `${window.location.pathname}/${TEST_TYPES.API}/${test.id}`
                    : `${window.location.pathname}/${TEST_TYPES.BROWSER}/${test.id}`
                }>
                <Paper className="team-card-style" data-testid={`test-card-${index}`}>
                  <Stack>
                    <Grid
                      container
                      data-testid="test-test-card-title"
                      alignItems="center"
                      justifyContent="space-between">
                      {test.name != undefined && test.name.length >= 22 ? (
                        <Tooltip title={test.name}>
                          <span>{test.name.substring(0, 22)}...</span>
                        </Tooltip>
                      ) : (
                        <span>{test.name}</span>
                      )}
                      <IconButton sx={{ mr: 0 }} onClick={(e) => handleDelete(e, index)}>
                        <img src={DeleteIcon} />
                      </IconButton>
                    </Grid>
                    <Grid className="description-style" data-testid="test-test-desc-field">
                      {test.description != undefined && test.description.length >= 30 ? (
                        <Tooltip title={test.description}>
                          <span>{test.description.substring(0, 30)}...</span>
                        </Tooltip>
                      ) : (
                        <span>{test.description}</span>
                      )}
                    </Grid>
                    {props.indicators.length > 0 && (
                      <Grid className="team-name-footer-style">
                        <Grid container>
                          {[...new Map(props.indicators.map((v) => [v.id, v])).values()].map(
                            (el) =>
                              el.id === test.id &&
                              el.result.map((indicator) => (
                                <Grid
                                  key={el.id}
                                  height={10}
                                  width={10}
                                  borderRadius="50%"
                                  mr={0.35}
                                  mt={1}
                                  sx={{
                                    backgroundColor: indicator ? '#36AF29' : '#B80012'
                                  }}></Grid>
                              ))
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Stack>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      <ConfirmDialog
        open={openDeleteModal}
        width="xs"
        title="Delete Test"
        handleSave={onDeleteTest}
        saveText="Delete"
        handleClose={onDeleteTestBtnClose}>
        Are you sure you want to delete this test? This cannot be undone after it is deleted.
      </ConfirmDialog>
    </>
  );
};
export default TestCard;
