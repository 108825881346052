import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const ConfirmDialog = (props) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          minHeight: props.minHeight,
          maxHeight: props.maxHeight
        }
      }}
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth={props.width ? props.width : 'md'}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent className="dialog-content-style">{props.children}</DialogContent>
      <DialogActions sx={{ background: '#F7F7F7' }}>
        <Button
          variant="outlined"
          sx={{
            '&:hover': {
              backgroundColor: props.saveText === 'Delete' ? '#fff' : '#00243D',
              color: props.saveText === 'Delete' ? 'black' : '#fff'
            }
          }}
          onClick={props.handleClose}
          data-testid="button-cancel">
          {props.cancelText ? props.cancelText : 'Cancel'}
        </Button>
        {!props.disableSaveActions && (
          <Button
            variant="contained"
            onClick={props.handleSave}
            sx={{
              backgroundColor: props.saveText === 'Delete' ? '#B80012' : '#00243D',
              '&:hover': {
                backgroundColor: props.saveText === 'Delete' ? '#A80012' : '#00243D'
              }
            }}
            disabled={props.disableSave ? props.disableSave : false}
            data-testid="button-save">
            {props.saveText ? props.saveText : 'Save Changes'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
