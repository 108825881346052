import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teams: [],
  allTeams: [],
  team: {
    teamId: '',
    teamName: '',
    description: '',
    teamMembers: []
  }
};
export const teamSlice = createSlice({
  name: 'teamSlice',
  initialState: initialState,
  reducers: {
    updateTeamList(state, action) {
      state.teams = action.payload;
    },
    updateAllTeamList(state, action) {
      state.allTeams = action.payload;
    },
    updateTeam(state, action) {
      state.team = action.payload;
    },
    resetTeam(state) {
      state.team = initialState.team;
    }
  }
});

export const teamActions = teamSlice.actions;
