const { Breadcrumbs } = require('@mui/material');
import { Link as RouterLink } from 'react-router-dom';
import './AppBreadCrumb.css';

const AppBreadCrumb = (props) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" className="shared-breadcrumb-m">
      {props.data.map((object, index) => (
        <RouterLink to={object.link} className="breadcrumbs-style" underline="hover" key={index}>
          {object.title}
        </RouterLink>
      ))}
    </Breadcrumbs>
  );
};

export default AppBreadCrumb;
