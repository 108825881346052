import { Grid, Paper, Stack, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import TeamMembersIcon from '../../assets/TeamMembersIcon.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './TeamCard.css';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { deleteTeam } from '../../store/slice/team/TeamActions';
import { commonActions } from '../../store/slice/CommonSlice';
import { teamActions } from '../../store/slice/team/TeamSlice';
import { fetchAllTeamsForUser } from '../../store/slice/team/TeamActions';

const TeamCard = (props) => {
  const teams = props.teams;
  const setTeamsChangeFlag = props.setTeamsChangeFlag;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [team, setTeam] = useState({});
  const onDeleteTeamBtnClose = () => setOpenDeleteModal(false);
  const dispatch = useDispatch();

  const handleDelete = (event, index) => {
    event.preventDefault();
    setOpenDeleteModal(true);
    setTeam(teams[index]);
  };

  const onDeleteTeam = () => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(deleteTeam(team.id)).then(
      () => {
        setTeamsChangeFlag && setTeamsChangeFlag(true);
        setOpenDeleteModal(false);
        dispatch(commonActions.showSuccessAlert(`Team ${team.id} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(teamActions.resetTeam());
        dispatch(fetchAllTeamsForUser(0));
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
        setOpenDeleteModal(false);
        dispatch(commonActions.showErrorAlert('Deletion of team is not allowed'));
      }
    );
  };

  return (
    <>
      {teams?.length === 0 && (
        <Grid container flex={1} justifyContent="center" mt={25}>
          You have no teams!
        </Grid>
      )}
      {teams?.length > 0 && (
        <Grid container className="container-style" justifyContent="flex-start">
          {teams.map((team, index) => (
            <Grid item key={index} lg={3}>
              <Link style={{ textDecoration: 'none' }} to={`/teams/${team.id}`}>
                <Paper className="team-card-style" data-testid={`team-card-${index}`}>
                  <Stack>
                    <Grid
                      container
                      data-testid="team-name-field"
                      alignItems="center"
                      justifyContent="space-between">
                      {team.description != undefined && team.description.length >= 25 ? (
                        <Tooltip title={team.description}>
                          <span>{team.description.substring(0, 25)}...</span>
                        </Tooltip>
                      ) : (
                        <span>{team.description}</span>
                      )}
                      <IconButton sx={{ mr: 0 }} onClick={(e) => handleDelete(e, index)}>
                        <img src={DeleteIcon} />
                      </IconButton>
                    </Grid>
                    <Grid className="description-style" data-testid="team-desc-field">
                      {team.teamName != undefined && team.teamName.length >= 70 ? (
                        <Tooltip title={team.teamName}>
                          <span>{team.teamName.substring(0, 70)}...</span>
                        </Tooltip>
                      ) : (
                        <span>{team.teamName}</span>
                      )}
                    </Grid>
                    <div className="team-name-footer-style">
                      <img
                        src={TeamMembersIcon}
                        data-testid="team-teamMemberCount-field"
                        style={{ marginRight: '.5rem', marginBottom: '.5rem' }}
                      />
                      {team.teamMemberCount > 1 ? team.teamMemberCount + ' members' : '1 member'}
                    </div>
                  </Stack>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      <ConfirmDialog
        open={openDeleteModal}
        width="xs"
        title="Delete Team"
        handleSave={onDeleteTeam}
        saveText="Delete"
        handleClose={onDeleteTeamBtnClose}>
        Are you sure you want to delete this team? This cannot be undone after it is deleted.
      </ConfirmDialog>
    </>
  );
};
export default TeamCard;
