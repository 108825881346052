import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '../../../assets/EditIcon.svg';
import DeleteIcon from '../../../assets/DeleteIcon.svg';

const CustomLabelsTable = ({ customLabels, setFormStateOnEdit, handleCustomLabels }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Label Name</TableCell>
            <TableCell>Label Value</TableCell>
            <TableCell colspan="2"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customLabels &&
            Object.entries(customLabels).map((customLabel, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      minWidth: '10rem',
                      maxWidth: '15rem',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}>
                    {customLabel[0]}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: '25rem',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}>
                    {customLabel[1]}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={(e) => setFormStateOnEdit(e, customLabel[0], customLabel[1])}>
                      <img src={EditIcon} />
                    </IconButton>
                    <IconButton onClick={(e) => handleCustomLabels(e, customLabel, true)}>
                      <img src={DeleteIcon} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomLabelsTable;
