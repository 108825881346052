import { Grid, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

const AssertionResult = (props) => {
  const errorMessage = props.error;
  const [headerAssertions, setHeaderAssertions] = useState([]);
  const [bodyAssertions, setBodyAssertions] = useState([]);
  const dots = ' . '.repeat(100);

  useEffect(() => {
    if (props.assertions !== undefined) {
      let headerAsserts = [];
      let bodyAsserts = [];
      props.assertions.map((assertion) => {
        if (assertion.assertionType === 'Header_Assertion') {
          headerAsserts.push(assertion);
          setHeaderAssertions(headerAsserts);
        } else if (assertion.assertionType === 'Body_Assertion') {
          bodyAsserts.push(assertion);
          setBodyAssertions(bodyAsserts);
        }
      });
    }
  }, [props.assertions]);

  return (
    <Grid sx={{ background: 'black', borderRadius: 1, p: 2, color: 'rgba(207, 207, 207, 1)' }}>
      <Grid container>
        {!errorMessage ? (
          <Grid item xs={8} mr={1}>
            Response Time: {props.responseTime} ms
          </Grid>
        ) : (
          <Tooltip title={errorMessage}>
            <Grid>
              Error:{' '}
              {errorMessage.length > 300 ? errorMessage.substring(0, 300) + '...' : errorMessage}
            </Grid>
          </Tooltip>
        )}
        {props.pauseDuration !== 0 && <Grid>Pause Duration: {props.pauseDuration} s</Grid>}
      </Grid>
      {props.assertions.length !== 0 && <hr style={{ background: 'grey' }} />}
      {[headerAssertions, bodyAssertions].map((assertionType, index) => {
        return (
          <Grid key={index} container sx={{ mb: 3 }}>
            {assertionType.length > 0 && (
              <>
                <Grid item sx={{ mb: 1, mr: 1 }} xs={8}>
                  {!index ? 'Header Assertions' : 'Body Assertions'}
                </Grid>
                <Grid item xs={3}>
                  Returned Value
                </Grid>
                {assertionType.map((assertion, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid
                        item
                        xs={8}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          mr: 1
                        }}>
                        {assertion.field} {assertion.operator} {assertion.expectedValue}{' '}
                        {assertion.result ? (
                          <DoneIcon color="success" />
                        ) : (
                          <ClearIcon color="error" />
                        )}{' '}
                        {dots}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                        {assertion.actualValue}
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AssertionResult;
