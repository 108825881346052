import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DefineStepForm from '../stepDetailsForms/DefineStepForm';
import MetaDataForm from '../stepDetailsForms/metadataForm/MetadataForm';
import AddAssertionsForm from '../stepDetailsForms/addAssertionsForm/AddAssertionsForm';
import StepDetailsTabContent from './StepDetailsTabContent';
import { useParams } from 'react-router-dom';
import AddVariablesForm from '../stepDetailsForms/addVariablesForm/AddVariablesForm';

const StepDetailsTabs = ({
  showStepforms,
  handleCreateStepOBJ,
  handleCreateStepInUpdateFlow,
  handleUpdateStepInUpdateFlow,
  handleClose,
  stepName,
  setStepName,
  stepDescription,
  setStepDescription,
  stepMethod,
  setStepMethod,
  stepURL,
  setStepURL,
  pauseDuration,
  setPauseDuration,
  operandsList,
  assertionType,
  setAssertionType,
  assertionField,
  setAssertionField,
  handleDeleteAssertion,
  assertionOperand,
  setAssertionOperand,
  assertionValue,
  setAssertionValue,
  assertionsList,
  openAssertionsModal,
  setOpenAssertionsModal,
  handleAddAssertion,
  edit,
  setEdit,
  headers,
  setHeaders,
  body,
  setBody,
  contentType,
  setContentType,
  showBodyTable,
  setShowBodyTable,
  extractVariablesList,
  setExtractVariablesList,
  override,
  // setOverride,
  secretsDetected
}) => {
  const [value, setValue] = useState(0);
  const { testId } = useParams();

  const handleChange = (e, newValue) => {
    e.preventDefault();
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [showStepforms]);

  return (
    <Grid
      hidden={showStepforms === false ?? true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '46vh'
      }}>
      <Grid sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="create step tabs">
          <Tab label="Define" />
          <Tab label="Metadata" />
          <Tab label="Assertions" />
          <Tab label="Extract Variables" />
        </Tabs>
      </Grid>
      <StepDetailsTabContent value={value} index={0}>
        <DefineStepForm
          stepName={stepName}
          setStepName={setStepName}
          stepDescription={stepDescription}
          setStepDescription={setStepDescription}
          stepMethod={stepMethod}
          setStepMethod={setStepMethod}
          stepURL={stepURL}
          setStepURL={setStepURL}
          pauseDuration={pauseDuration}
          setPauseDuration={setPauseDuration}
        />
      </StepDetailsTabContent>
      <StepDetailsTabContent value={value} index={1}>
        <MetaDataForm
          headers={headers}
          setHeaders={setHeaders}
          body={body}
          setBody={setBody}
          contentType={contentType}
          setContentType={setContentType}
          showBodyTable={showBodyTable}
          setShowBodyTable={setShowBodyTable}
        />
      </StepDetailsTabContent>
      <StepDetailsTabContent value={value} index={2}>
        <AddAssertionsForm
          openAssertionsModal={openAssertionsModal}
          setOpenAssertionsModal={setOpenAssertionsModal}
          operandsList={operandsList}
          assertionType={assertionType}
          setAssertionType={setAssertionType}
          assertionField={assertionField}
          setAssertionField={setAssertionField}
          assertionOperand={assertionOperand}
          setAssertionOperand={setAssertionOperand}
          assertionValue={assertionValue}
          setAssertionValue={setAssertionValue}
          assertionsList={assertionsList}
          handleAddAssertion={handleAddAssertion}
          handleDeleteAssertion={handleDeleteAssertion}
          edit={edit}
          setEdit={setEdit}
        />
      </StepDetailsTabContent>
      <StepDetailsTabContent value={value} index={3}>
        <AddVariablesForm
          extractVariablesList={extractVariablesList}
          setExtractVariablesList={setExtractVariablesList}
        />
      </StepDetailsTabContent>
      {/* ToDo: Uncomment to enable secret scanning feature in steps
      <Grid spacing={1}>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={false}
              onChange={(e) => {
                setOverride(e.target.checked);
              }}
            />
          }
          sx={{ marginTop: 2 }}
          label="Override Secret Scan"
          labelPlacement="end"
        />
        <Tooltip title="Enable this option if you believe the detected issue is not a genuine threat or is a false positive.">
          <InfoIcon
            style={{ color: '#00243D', marginTop: '0.5rem', marginLeft: '-0.6rem' }}
            className="override-info"></InfoIcon>
        </Tooltip>
      </Grid> */}
      <Grid>
        {!edit && (
          <Button
            disabled={
              (secretsDetected && !override) ||
              stepName.length === 0 ||
              stepURL.length === 0 ||
              assertionsList.length === 0
            }
            onClick={testId ? handleCreateStepInUpdateFlow : handleCreateStepOBJ}
            variant="contained">
            Create Step
          </Button>
        )}
        {edit && (
          <Button
            disabled={
              (secretsDetected && !override) || stepName.length === 0 || stepURL.length === 0
            }
            onClick={testId ? handleUpdateStepInUpdateFlow : handleCreateStepOBJ}
            variant="contained">
            Update Step
          </Button>
        )}
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default StepDetailsTabs;
