import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import ConfirmDialog from '../../../components/modal/ConfirmDialog';

const TestExportForm = (props) => {
  const handleCopyJson = async () => {
    try {
      await navigator.clipboard.writeText(props.jsonData);
      props.setOpenExportTestModal(false);
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
    }
  };

  const handleTestDownload = () => {
    const blob = new Blob([props.jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Test_${props.test.id}_${props.test.name}.json`;
    a.click();
    URL.revokeObjectURL(url);
    props.setOpenExportTestModal(false);
  };
  return (
    <ConfirmDialog
      open={props.open}
      width={props.width}
      title={props.title}
      saveText="Download"
      cancelText="Copy"
      handleSave={handleTestDownload}
      handleClose={handleCopyJson}>
      <Grid container>
        <TextField
          sx={{ my: '0.5rem' }}
          id="test-json"
          label="Test as JSON"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={9}
          value={props.jsonData}
        />
      </Grid>
    </ConfirmDialog>
  );
};

export default TestExportForm;
