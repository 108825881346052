import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const StepAnimation = ({ steps, activeStep }) => {
  return (
    // CRUFT: we shouldn't need to set a rigid height
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step) => {
        return (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepAnimation;
