import { Grid, LinearProgress } from '@mui/material';
import ConfirmDialog from '../../../components/modal/ConfirmDialog';
import { useOutletContext } from 'react-router-dom';
import TestRunDetails from '../testRun/TestRunDetails';
import moment from 'moment';
import axiosInstance from '../../../services/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { TEST_TYPES } from '../../../utils/TestTypes';
import { useSelector } from 'react-redux';

const TestResultModalReadOnly = () => {
  const { suiteId, testId, resultId } = useParams();
  const [showResult, setShowResult] = useOutletContext();
  const navigate = useNavigate();
  const [resultData, setResultData] = useState({});
  const type =
    `${window.location.pathname.indexOf(TEST_TYPES.BROWSER)}` === '-1'
      ? TEST_TYPES.API
      : TEST_TYPES.BROWSER;
  const test = type === TEST_TYPES.BROWSER && useSelector((state) => state.testData.browserTest);

  const handleClose = () => {
    setShowResult(false);
    navigate(`${window.location.pathname.split('/result', 1)}`);
  };

  useEffect(async () => {
    if (resultId !== undefined) {
      axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${suiteId}/${
          type === TEST_TYPES.API ? 'tests' : 'browser_tests'
        }/${testId}/results/${resultId}?expand=true`
      })
        .then((response) => {
          setResultData(response.data);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, []);

  return (
    <ConfirmDialog
      open={showResult}
      minHeight="550px"
      title="Test Result"
      disableSaveActions="true"
      cancelText="Close"
      handleClose={handleClose}>
      <Grid container justifyContent="space-between" sx={{ color: '#353535' }}>
        <Grid>
          <Grid>
            <b>Test Date</b>
          </Grid>
          <Grid>
            {moment
              .utc(
                resultData.updatedTimestamp && resultData.updatedTimestamp.replace('UTC', '').trim()
              )
              .local()
              .format('DD MMM YY, HH:mm:ss')}
          </Grid>
        </Grid>
        <Grid>
          <Grid>
            <b>Environment</b>
          </Grid>
          <Grid>{resultData?.environmentDTO?.name}</Grid>
        </Grid>
        <Grid>
          <Grid>
            <b>Name</b>
          </Grid>
          <Grid>
            {type === TEST_TYPES.API ? resultData?.testDTO?.name : resultData?.browserTestDTO?.name}
          </Grid>
        </Grid>
      </Grid>
      <hr />
      {isEmpty(resultData) ? (
        <LinearProgress sx={{ my: 20 }} />
      ) : (
        <TestRunDetails testResults={[resultData]} type={type} test={test} />
      )}
    </ConfirmDialog>
  );
};
export default TestResultModalReadOnly;
