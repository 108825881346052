import axiosInstance from '../../../services/axios';
import { commonActions } from '../CommonSlice';
import { variableActions } from './VariableSlice';

export const fetchAllVariablesInEnvironment = (suiteId, environmentId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${suiteId}/environments/${environmentId}/variables`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const variables = await fetchData();
      dispatch(variableActions.updateVariableList(variables));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchVariableById = (suiteId, environmentId, id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${suiteId}/environments/${environmentId}/variables/${id}`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const variable = await fetchData();
      dispatch(variableActions.updateVariable(variable));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const createVariable = (suiteId, environmentId, variableData) => {
  return (dispatch) => {
    const addData = async () => {
      await axiosInstance({
        method: 'post',
        url: `/v1/test_suites/${suiteId}/environments/${environmentId}/variables`,
        data: variableData
      });
    };
    try {
      return addData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const updateVariable = (suiteId, environmentId, id, variableData) => {
  return (dispatch) => {
    const updateData = async () => {
      await axiosInstance({
        method: 'put',
        url: `/v1/test_suites/${suiteId}/environments/${environmentId}/variables/${id}`,
        data: variableData
      });
    };
    try {
      return updateData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteVariable = (suiteId, environmentId, id) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/test_suites/${suiteId}/environments/${environmentId}/variables/${id}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};
