import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const AddCustomLabelModelContent = ({ labelName, setLabelName, labelValue, setLabelValue }) => {
  return (
    <Box component="form" noValidate autoComplete="off">
      <TextField
        margin="dense"
        label="Label Name"
        type="text"
        fullWidth
        variant="outlined"
        name="label-name"
        value={labelName}
        sx={{ mb: 2 }}
        onChange={(e) => {
          setLabelName(e.target.value);
        }}
      />
      <TextField
        margin="dense"
        label="Label Value"
        type="text"
        fullWidth
        variant="outlined"
        multiline
        rows={4}
        name="label-value"
        value={labelValue}
        onChange={(e) => {
          setLabelValue(e.target.value);
        }}
      />
    </Box>
  );
};

export default AddCustomLabelModelContent;
