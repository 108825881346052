import { Grid, Paper, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { deleteSuite } from '../../store/slice/suite/SuiteActions';
import { commonActions } from '../../store/slice/CommonSlice';
import { suiteActions } from '../../store/slice/suite/SuiteSlice';
import { fetchAllSuitesForUser } from '../../store/slice/suite/SuiteActions';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '../../assets/DeleteIcon.svg';

const TestSuiteCard = (props) => {
  const suites = props.suites;
  const setSuitesChangeFlag = props.setSuitesChangeFlag;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [suite, setSuite] = useState({});
  const onDeleteSuiteBtnClose = () => setOpenDeleteModal(false);
  const dispatch = useDispatch();

  const handleDelete = (event, index) => {
    event.preventDefault();
    setOpenDeleteModal(true);
    setSuite(suites[index]);
  };

  const onDeleteSuite = () => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(deleteSuite(suite.id)).then(
      () => {
        setSuitesChangeFlag && setSuitesChangeFlag(true);
        setOpenDeleteModal(false);
        dispatch(commonActions.showSuccessAlert(`Test suite ${suite.name} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(suiteActions.resetSuite());
        dispatch(fetchAllSuitesForUser(0));
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        setOpenDeleteModal(false);
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  return (
    <>
      {suites?.length === 0 && (
        <Grid container flex={1} justifyContent="center" mt={25}>
          You have no suites!
        </Grid>
      )}
      {suites?.length > 0 && (
        <Grid container className="container-style" justifyContent="flex-start">
          {suites.map((suite, index) => (
            <Grid item key={index} lg={3}>
              <Link style={{ textDecoration: 'none' }} to={`/suites/${suite.id}`}>
                <Paper
                  className="team-card-style"
                  style={{
                    borderLeft:
                      props.indicators &&
                      props.indicators.some((el) => el.id === suite.id && el.failedFlag === true)
                        ? '6px solid #B80012'
                        : 'none'
                  }}
                  data-testid={`suite-card-${index}`}>
                  <Stack>
                    <Grid
                      container
                      data-testid="test-suite-card-title"
                      alignItems="center"
                      justifyContent="space-between">
                      {suite.name != undefined && suite.name.length >= 25 ? (
                        <Tooltip title={suite.name}>
                          <span
                            style={{
                              color:
                                props.indicators &&
                                props.indicators.some(
                                  (el) => el.id === suite.id && el.failedFlag === true
                                )
                                  ? '#B80012'
                                  : 'none'
                            }}>
                            {suite.name.substring(0, 25)}...
                          </span>
                        </Tooltip>
                      ) : (
                        <span
                          style={{
                            color:
                              props.indicators &&
                              props.indicators.some(
                                (el) => el.id === suite.id && el.failedFlag === true
                              )
                                ? '#B80012'
                                : 'none'
                          }}>
                          {suite.name}
                        </span>
                      )}
                      <IconButton sx={{ mr: 0 }} onClick={(e) => handleDelete(e, index)}>
                        <img src={DeleteIcon} />
                      </IconButton>
                    </Grid>
                    <Grid className="description-style" data-testid="test-suite-desc-field">
                      {suite.description != undefined && suite.description.length >= 30 ? (
                        <Tooltip title={suite.description}>
                          <span>{suite.description.substring(0, 30)}...</span>
                        </Tooltip>
                      ) : (
                        <span>{suite.description}</span>
                      )}
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      width="245px"
                      className="team-name-footer-style"
                      data-testid="team-name-field">
                      {suite.teamName != undefined && suite.teamName.length >= 30 ? (
                        <Tooltip title={suite.teamName}>
                          <Grid>{suite.teamName.substring(0, 30)}...</Grid>
                        </Tooltip>
                      ) : (
                        <Grid>{suite.teamName}</Grid>
                      )}
                      <Grid
                        sx={{
                          mr:
                            props.indicators &&
                            props.indicators.some(
                              (el) => el.id === suite.id && el.failedFlag === true
                            )
                              ? 0.5
                              : 0,
                          color:
                            props.indicators &&
                            props.indicators.some(
                              (el) => el.id === suite.id && el.failedFlag === true
                            )
                              ? '#B80012'
                              : 'none'
                        }}>
                        {suite.testCount}
                      </Grid>
                    </Grid>
                  </Stack>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      <ConfirmDialog
        open={openDeleteModal}
        width="xs"
        title="Delete Test Suite"
        handleSave={onDeleteSuite}
        saveText="Delete"
        handleClose={onDeleteSuiteBtnClose}>
        Are you sure you want to delete this test suite? This cannot be undone after it is deleted.
      </ConfirmDialog>
    </>
  );
};
export default TestSuiteCard;
