import { maerskTheme } from './theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { LoginPage } from './views/auth/LoginPage';
import HomePage from './views/home/HomePage';
import TeamDetails from './views/team/TeamDetails';
import { Route, Routes } from 'react-router-dom';
import { loginRequest } from './authConfig';
import { useMsal } from '@azure/msal-react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import { commonActions } from './store/slice/CommonSlice';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TestSuiteDetails from './views/suites/TestSuiteDetails';
import CreateTest from './views/createTest/CreateTest';

const theme = createTheme(maerskTheme);
import EnvironmentDetails from './views/environment/EnvironmentDetails';
import TestDetails from './views/tests/TestDetails';
import TestResultModalReadOnly from './views/tests/testRun/TestResultModalReadOnly';

const ProfileContent = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const request = {
    ...loginRequest,
    account: accounts[0]
  };

  instance.acquireTokenSilent(request).then((response) => {
    window.sessionStorage.setItem('madeye-token', response.accessToken);
    dispatch(
      commonActions.setUser({
        username: accounts[0].username,
        name: accounts[0].name
      })
    );
  });
  const user = useSelector((state) => state.generalData.user);

  return (
    <>
      {user.name.length > 0 && (
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/teams/:id" element={<TeamDetails />} />
          <Route path="/suites/:suiteId/tests" element={<CreateTest />} />
          <Route path="/suites/:suiteId/browser-tests" element={<CreateTest />} />
          <Route path="/suites/:suiteId/tests/:testId/update" element={<CreateTest />} />
          <Route path="/suites/:suiteId/browser-tests/:testId/update" element={<CreateTest />} />
          <Route path="/suites/:suiteId" element={<TestSuiteDetails />} />
          <Route path="/suites/:suiteId/environments/:id" element={<EnvironmentDetails />} />
          <Route path="/suites/:suiteId/tests/:testId" element={<TestDetails />}>
            <Route
              path="/suites/:suiteId/tests/:testId/result/:resultId"
              element={<TestResultModalReadOnly />}
            />
          </Route>
          <Route path="/suites/:suiteId/browser-tests/:testId" element={<TestDetails />}>
            <Route
              path="/suites/:suiteId/browser-tests/:testId/result/:resultId"
              element={<TestResultModalReadOnly />}
            />
          </Route>
        </Routes>
      )}
    </>
  );
};

const MainContent = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ fontFamily: 'Maersk Text' }}>
        <AuthenticatedTemplate>
          <Provider store={store}>
            <Header />
            <ProfileContent />
            <Footer />
          </Provider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage />
        </UnauthenticatedTemplate>
      </div>
    </ThemeProvider>
  );
};

export default function App() {
  return <MainContent />;
}
