import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  loadingCounter: 0,
  alert: {},
  user: {
    name: '',
    email: ''
  }
};
export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: initialState,
  reducers: {
    showLoadingIcon(state, action) {
      const loadingState = action.payload;
      if (loadingState) {
        state.loadingCounter++;
        state.loading = true;
      } else {
        state.loadingCounter--;
        if (state.loadingCounter <= 0) {
          state.loading = false;
          state.loadingCounter = 0;
        }
      }
    },
    showSuccessAlert(state, action) {
      state.alert.show = true;
      state.alert.type = 'success';
      state.alert.message = action.payload;
    },
    showErrorAlert(state, action) {
      state.alert.show = true;
      state.alert.type = 'error';
      state.alert.message = action.payload;
    },
    clearAlert(state) {
      state.alert.show = false;
    },
    setUser(state, action) {
      state.user.email = action.payload.username;
      state.user.name = action.payload.name;
    }
  }
});

export const commonActions = commonSlice.actions;
