import './Footer.css';
import footerLogo from '../../assets/FooterLogo.svg';

function Footer() {
  return (
    <footer className="footer">
      <div>
        <img src={footerLogo} alt={''} height={32} className="logo" />
        <div>© {new Date().getFullYear()} A.P. Moller - Maersk</div>
      </div>
      <div className="links">
        <a
          href={`${process.env.REACT_APP_SWAGGER_URL}`}
          target="_blank"
          rel="noreferrer"
          className="link">
          API
        </a>
        <a
          href="https://github.com/Maersk-Global/observability-the-pensieve/discussions"
          target="_blank"
          rel="noreferrer"
          className="link">
          Discussion
        </a>
        <a
          href="https://docs.mop.maersk.io/synthetic/"
          target="_blank"
          rel="noreferrer"
          className="link">
          Documentation
        </a>
        <a
          href="https://teams.microsoft.com/l/channel/19%3a9a9f7ecc18c84854ad338d9667aa52a6%40thread.tacv2/Support?groupId=f358a000-b2c3-4d58-b1e5-d3927ff9914b&tenantId=05d75c05-fa1a-42e7-9cf1-eb416c396f2d"
          target="_blank"
          rel="noreferrer"
          className="link">
          Support
        </a>
      </div>
    </footer>
  );
}

export default Footer;
