import axiosInstance from '../../../services/axios';
import { commonActions } from '../CommonSlice';
import { productActions } from './ProductSlice';

export const fetchAllProducts = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: 'v1/ui/products'
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const products = await fetchData();
      dispatch(productActions.updateProductList(products));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};
