import { Grid, Stack, Button, Autocomplete, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import ConfirmDialog from '../../../components/modal/ConfirmDialog';
import TestResultModal from './TestResultModal';
import ReplayIcon from '@mui/icons-material/Replay';

const RunTest = (props) => {
  const environments = props.environments;
  const [openRunTestModal, setOpenRunTestModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [envOptions, setEnvOptions] = useState([]);
  const [env, setEnv] = useState({});

  const handleOpenRunTestModal = () => {
    setOpenRunTestModal(true);
  };

  const handleCloseRunTestModal = () => {
    setEnv({});
    setOpenRunTestModal(false);
  };

  const handleRunNow = () => {
    setOpenRunTestModal(false);
    setOpenResultModal(true);
  };

  useEffect(() => {
    if (environments != undefined && environments.length > 0) {
      let options = environments.map((x) => {
        return {
          label: x.name,
          id: x.id
        };
      });
      setEnvOptions(options);
    }
  }, [environments]);

  return (
    <Grid item xs={10.5}>
      <Stack direction="row-reverse">
        <Button variant="outlined" onClick={handleOpenRunTestModal}>
          Run Test <ReplayIcon fontSize="small" sx={{ transform: 'scaleX(-1)' }} />
        </Button>
      </Stack>
      <ConfirmDialog
        open={openRunTestModal}
        width="xs"
        title="Run Test"
        saveText="Run Now"
        disableSave={!env.id}
        handleSave={handleRunNow}
        handleClose={handleCloseRunTestModal}>
        To run test, first choose the environment you wish to run it inside of.
        <Autocomplete
          sx={{ marginTop: 3, marginBottom: 5 }}
          options={envOptions}
          required
          disabled={environments.length == 0}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderInput={(params) => <TextField {...params} label="Select Environment" />}
          onChange={(_event, newValue) => {
            setEnv({ id: newValue.id, name: newValue.label });
          }}
        />
      </ConfirmDialog>
      <TestResultModal
        open={openResultModal}
        setOpenResultModal={setOpenResultModal}
        test={props.test}
        type={props.type}
        environment={env}
      />
    </Grid>
  );
};
export default RunTest;
