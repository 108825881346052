import { Grid, LinearProgress } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import ConfirmDialog from '../../../components/modal/ConfirmDialog';
import TestRunDetails from '../testRun/TestRunDetails';
import axiosInstance from '../../../services/axios';
import { TEST_TYPES } from '../../../utils/TestTypes';

const TestResultModal = (props) => {
  const [showResult, setShowResult] = useState(false);
  const [testRunIds, setTestRunIds] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [apiStart, setApiStart] = useState(false);
  const [runAgainFlag, setRunAgainFlag] = useState(false);
  const [count, setCount] = useState(1);
  const [failedRunIds, setFailedRunIds] = useState([]);

  const pollResult = () => {
    setCount(1);
    setApiStart(true);
  };

  const getAPI = useCallback(
    async (runIds, retryCount) => {
      if (runIds.length > 0 && retryCount <= 10) {
        setResultData((prevResultData) => [...prevResultData]);
        const failedRunIds = [];
        const promises = runIds.map((runId) => {
          const existingResult = resultData.find((result) => result.runId === runId);
          if (existingResult) {
            return Promise.resolve(existingResult);
          } else {
            return axiosInstance({
              method: 'get',
              url: `/v1/test_suites/${props.test.testSuiteId}/${
                props.type === TEST_TYPES.API ? 'tests' : 'browser_tests'
              }/${props.test.id}/results/${runId}`
            })
              .then((response) => {
                setResultData((prevResultData) => [...prevResultData, response.data]);
              })
              .catch(() => {
                failedRunIds.push(runId);
                setFailedRunIds(failedRunIds);
              });
          }
        });
        Promise.all(promises)
          .then(() => {
            if (failedRunIds.length > 0) {
              setTimeout(
                () => {
                  setCount(retryCount);
                  getAPI(failedRunIds, retryCount + 1);
                  setFailedRunIds([]);
                },
                props.type === TEST_TYPES.API ? 1000 : 5000
              );
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    },
    [resultData]
  );

  useEffect(() => {
    if (apiStart) {
      getAPI(testRunIds, 1);
    }
  }, [apiStart]);

  useEffect(() => {
    if (apiStart && (resultData.length === testRunIds.length || count === 10)) {
      setShowResult(true);
    }
  }, [apiStart, count, resultData]);

  useEffect(async () => {
    setFailedRunIds([]);
    setTestRunIds([]);
    setShowResult(false);
    if (props.environment.id !== undefined && props.open) {
      setResultData([]);
      setApiStart(false);
      await axiosInstance({
        method: 'post',
        url: `/v1/test_suites/${props.test.testSuiteId}/tests/${
          props.test.id
        }/execute?environmentId=${props.environment.id}&browserTest=${
          props.type === TEST_TYPES.BROWSER
        }`
      })
        .then((response) => {
          setTestRunIds(response.data);
          setApiStart(true);
        })
        .catch((error) => {
          console.log('error', error);
          handleClose();
        });
    }
  }, [props.open, runAgainFlag]);

  useEffect(() => {
    testRunIds.length != 0 && pollResult();
  }, [testRunIds]);

  const handleRunAgain = () => {
    setShowResult(false);
    setRunAgainFlag(!runAgainFlag);
    testRunIds.length != 0 && pollResult();
  };

  const handleClose = () => {
    props.setOpenResultModal(false);
    window.location.reload();
  };

  return (
    <ConfirmDialog
      open={props.open}
      minHeight="550px"
      title="Test Result"
      saveText="Run Again"
      disableSave={!showResult && count !== 10}
      handleSave={handleRunAgain}
      handleClose={handleClose}>
      <Grid container justifyContent="space-between" sx={{ color: '#353535' }}>
        <Grid>
          <Grid>
            <b>Test Date</b>
          </Grid>
          <Grid>{new Date().toLocaleString()}</Grid>
        </Grid>
        <Grid>
          <Grid>
            <b>Environment</b>
          </Grid>
          <Grid>{props.environment.name}</Grid>
        </Grid>
        <Grid>
          <Grid>
            <b>Name</b>
          </Grid>
          <Grid>{props.test.name}</Grid>
        </Grid>
      </Grid>
      <hr />
      {!showResult && count < 10 && <LinearProgress sx={{ my: 20 }} />}
      {showResult && (
        <TestRunDetails
          testResults={resultData}
          failedRunIds={failedRunIds}
          multiLocation={testRunIds.length > 1}
          type={props.type}
          test={props.test}
        />
      )}
    </ConfirmDialog>
  );
};
export default TestResultModal;
