import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  environments: [],
  environment: {
    id: '',
    name: '',
    description: '',
    locations: []
  }
};
export const environmentSlice = createSlice({
  name: 'environmentSlice',
  initialState: initialState,
  reducers: {
    updateEnvironmentList(state, action) {
      state.environments = action.payload;
    },
    updateEnvironment(state, action) {
      state.environment = action.payload;
    },
    resetEnvironment(state) {
      state.environment = initialState.environment;
    }
  }
});

export const environmentActions = environmentSlice.actions;
