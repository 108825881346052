import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '../../../../../../assets/EditIcon.svg';
import DeleteIcon from '../../../../../../assets/DeleteIcon.svg';

const HeadersTable = ({ headers, setHeaderFormOnEdit, handleHeaders }) => {
  return (
    <TableContainer component={Paper} sx={{ my: '2rem' }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell colSpan={4}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(headers).map((header, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{header[0]}</TableCell>
                <TableCell>{header[1]}</TableCell>
                <TableCell colSpan={3} align="right">
                  <IconButton onClick={(e) => setHeaderFormOnEdit(e, header[0], header[1])}>
                    <img src={EditIcon} />
                  </IconButton>
                  <IconButton onClick={(e) => handleHeaders(e, header, true)}>
                    <img src={DeleteIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HeadersTable;
