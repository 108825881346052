export const maerskTheme = {
  // pallete overrides for primary, secondary, error, warning, info, & success
  palette: {
    primary: {
      main: '#00243D',
      dark: '#141414'
    },
    bgSecondary: '#bfd7dd33'
  },
  // component specific overrides. If this list grows too long, break out objects into seperate files
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          marginRight: '1rem',
          minWidth: '6rem',
          img: {
            marginRight: '.5rem'
          }
        },
        outlined: {
          '&:hover': {
            background: '#00243D',
            color: 'white'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          marginRight: '.5rem',
          '&:hover': {
            background: 'none'
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: '#F7F7F7'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold'
        }
      }
    }
  }
};
