import axiosInstance from '../../../services/axios';
import { commonActions } from '../CommonSlice';
import { testActions } from './TestSlice';
import { TEST_TYPES } from '../../../utils/TestTypes';

export const createTest = (testSuiteID, testData) => {
  return (dispatch) => {
    const addTest = async () => {
      await axiosInstance({
        method: 'post',
        url: `/v1/test_suites/${testSuiteID}/tests/composite`,
        data: testData
      });
    };
    try {
      return addTest();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const createBrowserTest = (testSuiteID, testData) => {
  return (dispatch) => {
    const addTest = async () => {
      await axiosInstance({
        method: 'post',
        url: `/v1/test_suites/${testSuiteID}/browser_tests/composite`,
        data: testData
      });
    };
    try {
      return addTest();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchAllTestsInTestSuite = (suiteId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${suiteId}/tests`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const tests = await fetchData();
      dispatch(testActions.updateTestList(tests));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchAllBrowserTestsInTestSuite = (suiteId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${suiteId}/browser_tests`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const tests = await fetchData();
      dispatch(testActions.updateBrowserTestList(tests));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchTestById = (suiteId, id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${suiteId}/tests/${id}?expand=true`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const test = await fetchData();
      dispatch(testActions.updateTest(test));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchBrowserTestById = (suiteId, id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${suiteId}/browser_tests/${id}?expand=true`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const test = await fetchData();
      dispatch(testActions.updateBrowserTest(test));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const updateTest = (suiteId, testId, testData) => {
  return (dispatch) => {
    const updateData = async () => {
      await axiosInstance({
        method: 'put',
        url: `/v1/test_suites/${suiteId}/tests/${testId}`,
        data: testData
      });
    };
    try {
      return updateData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const updateBrowserTest = (suiteId, testId, testData) => {
  return (dispatch) => {
    const updateData = async () => {
      await axiosInstance({
        method: 'put',
        url: `/v1/test_suites/${suiteId}/browser_tests/${testId}`,
        data: testData
      });
    };
    try {
      return updateData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteTest = (suiteId, id) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/test_suites/${suiteId}/tests/${id}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteBrowserTest = (suiteId, id) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/test_suites/${suiteId}/browser_tests/${id}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

// -----------------------------------------------
// ---------PUT POST PATCH EXISTING TEST----------
// -----------------------------------------------

export const createSchedule = (suiteId, testId, scheduleData) => {
  return (dispatch) => {
    const addSchedule = async () => {
      await axiosInstance({
        method: 'post',
        url: `/v1/test_suites/${suiteId}/tests/${testId}/schedules`,
        data: scheduleData
      });
    };
    try {
      return addSchedule();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const updateSchedule = (suiteId, testId, scheduleId, scheduleData) => {
  return (dispatch) => {
    const updateData = async () => {
      await axiosInstance({
        method: 'put',
        url: `/v1/test_suites/${suiteId}/tests/${testId}/schedules/${scheduleId}`,
        data: scheduleData
      });
    };
    try {
      return updateData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteSchedule = (suiteId, testId, scheduleId) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/test_suites/${suiteId}/tests/${testId}/schedules/${scheduleId}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteStep = (suiteId, testId, stepId) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/test_suites/${suiteId}/tests/${testId}/steps/${stepId}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const rearrangeStep = (suiteId, testId, stepId, rearrangeStepData) => {
  return (dispatch) => {
    const rearrangeData = async () => {
      const response = await axiosInstance({
        method: 'put',
        url: `/v1/test_suites/${suiteId}/tests/${testId}/steps/${stepId}/rearrange`,
        data: rearrangeStepData
      });
      return response.data;
    };
    try {
      return rearrangeData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const createAssertion = (suiteId, testId, stepId, assertionData) => {
  return (dispatch) => {
    const addAssertion = async () => {
      await axiosInstance({
        method: 'post',
        url: `/v1/test_suites/${suiteId}/tests/${testId}/steps/${stepId}/assertions`,
        data: assertionData
      });
    };
    try {
      return addAssertion();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const updateAssertion = (suiteId, testId, stepId, assertionId, assertionData) => {
  return (dispatch) => {
    const updateData = async () => {
      await axiosInstance({
        method: 'put',
        url: `/v1/test_suites/${suiteId}/tests/${testId}/steps/${stepId}/assertions/${assertionId}`,
        data: assertionData
      });
    };
    try {
      return updateData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteAssertion = (suiteId, testId, stepId, assertionId) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/test_suites/${suiteId}/tests/${testId}/steps/${stepId}/assertions/${assertionId}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

// -------------------------------------
// -------------------------------------
// -------------------------------------

export const fetchTestResultHistory = (type, suiteId, testId, page, pageSize, setPageState) => {
  return async () => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${suiteId}/${
          type === TEST_TYPES.API ? 'tests' : 'browser_tests'
        }/${testId}/results?page=${page}&countPerPage=${pageSize}`
      });
      const data = response.data;
      return data;
    };
    try {
      setPageState((old) => ({ ...old, isLoading: true }));
      const results = await fetchData();
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: type === TEST_TYPES.API ? results.testResultDTO : results.browserTestResultDTO,
        total: results.totalAvailableResults > 500 ? 500 : results.totalAvailableResults
      }));
    } catch (error) {
      setPageState((old) => ({
        ...old,
        isLoading: false
      }));
      console.log('error', error);
    }
  };
};
