import { Tooltip, InputLabel, MenuItem, FormControl, Select, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import AddCustomLabelsForm from './AddCustomLabelsForm';

const AlertConditionsForm = ({
  step,
  handleAlertCondition,
  webhookError,
  alertCondition,
  setAlertCondition,
  alertWebhook,
  customLabels,
  setCustomLabels,
  environment
}) => {
  const min = 1;
  const max = 5;
  const [openCustomLabelsModal, setOpenCustomLabelsModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const getAllowedThreshold = (value) => {
    let allowedValue = parseInt(value, 10);
    if (allowedValue > max) allowedValue = max;
    if (allowedValue < min) allowedValue = min;
    return allowedValue;
  };

  return (
    <Grid hidden={step != 5} sx={{ my: '2rem', width: '100%' }}>
      <Grid>
        <FormControl sx={{ minWidth: 300, mr: '1rem' }}>
          <InputLabel>Type</InputLabel>
          <Select
            sx={{ background: 'white', fontSize: environment && 14 }}
            label="Type"
            defaultValue={alertCondition ? alertCondition.type : ''}
            name="type"
            onChange={(e) => handleAlertCondition(e.target.value, 'type')}>
            <MenuItem value={''}>None</MenuItem>
            <MenuItem value={'consecutive failures'}>Consecutive Failures</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Maximum allowed threshold is 5!">
          <TextField
            sx={{
              my: '0',
              mx: '1rem',
              width: 150,
              background: 'white'
            }}
            inputProps={{ min, max, style: { fontSize: environment && 14 } }}
            type="number"
            variant="outlined"
            value={alertCondition ? alertCondition.threshold : ''}
            label="Threshold"
            id="threshold"
            onChange={(e) => handleAlertCondition(getAllowedThreshold(e.target.value), 'threshold')}
          />
        </Tooltip>
      </Grid>
      <Grid sx={{ mt: '1.5rem' }}>
        <TextField
          sx={{ width: 480, background: 'white' }}
          inputProps={{ style: { fontSize: environment && 14 } }}
          variant="outlined"
          value={alertWebhook ? alertWebhook : ''}
          label="Webhook"
          id="alert-webhook"
          onChange={(e) => handleAlertCondition(e.target.value, 'webhook')}
          error={!!webhookError}
          helperText={webhookError}
        />
      </Grid>
      <AddCustomLabelsForm
        setOpenCustomLabelsModal={setOpenCustomLabelsModal}
        openCustomLabelsModal={openCustomLabelsModal}
        customLabels={customLabels}
        setCustomLabels={setCustomLabels}
        edit={edit}
        setEdit={setEdit}
        alertCondition={alertCondition}
        alertWebhook={alertWebhook}
        setAlertCondition={setAlertCondition}
        environment={environment}
      />
    </Grid>
  );
};

export default AlertConditionsForm;
