import { useParams } from 'react-router-dom';
import { TEST_TYPES } from '../../utils/TestTypes';
import Button from '@mui/material/Button';

const FormButtons = ({
  name,
  description,
  startingUrl,
  browserDeviceMappings,
  schedules,
  steps,
  script,
  step,
  setStep,
  setActiveStep,
  handleCancel,
  handleTestOBJ,
  handleUpdate,
  alertCondition,
  alertWebhook,
  webhookError,
  type,
  scriptValidateFlag
}) => {
  const { testId } = useParams();

  const submitButtonText = () => {
    if (step === steps.length - 1 && testId) {
      return 'Save and Done';
    } else if (step === steps.length - 1 && !testId) {
      return 'Create Test';
    } else {
      return testId ? 'Save and Next' : 'Next';
    }
  };

  const isNextButtonDisabled = (step) => {
    switch (step) {
      case 0:
        return !(
          name.length !== 0 &&
          description.length !== 0 &&
          (type === TEST_TYPES.BROWSER
            ? startingUrl.length !== 0 && browserDeviceMappings.length !== 0
            : true)
        );
      case 2:
        return schedules.some((schedule) => schedule.duration === undefined);
      case 3:
        return type === TEST_TYPES.BROWSER && (script.length == 0 || !scriptValidateFlag);
      case 5:
        return !(
          !alertCondition ||
          (alertCondition &&
            alertCondition.threshold &&
            alertCondition.type &&
            alertWebhook &&
            !webhookError) ||
          (alertCondition && !alertCondition.threshold && !alertCondition.type && !alertWebhook)
        );
      default:
        return false;
    }
  };

  return (
    <>
      <Button
        disabled={step < 1}
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          setStep(step - 1);
          setActiveStep(step - 1);
          testId && handleUpdate(step - 1);
        }}>
        Back
      </Button>
      <Button
        disabled={isNextButtonDisabled(step)}
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          if (step === steps.length - 1) {
            !testId && handleTestOBJ(type);
            testId && handleUpdate(-1);
          } else {
            setStep(step + 1);
            setActiveStep(step + 1);
            testId && handleUpdate(step + 1);
          }
        }}>
        {submitButtonText()}
      </Button>
      <Button
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          handleCancel();
          setStep(0);
          setActiveStep(0);
        }}>
        {testId ? 'Close' : 'Cancel'}
      </Button>
    </>
  );
};

export default FormButtons;
