import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const AddAssertionsModalContent = ({
  operandsList,
  assertionType,
  setAssertionType,
  assertionField,
  setAssertionField,
  assertionOperand,
  setAssertionOperand,
  assertionValue,
  setAssertionValue
}) => {
  return (
    <Box component="form" noValidate autoComplete="off" sx={{ my: '2rem' }}>
      <FormControl sx={{ minWidth: 550, mb: '1rem' }}>
        <InputLabel>Type</InputLabel>
        <Select
          label="Type"
          defaultValue=""
          name="assertion-type"
          value={assertionType}
          onChange={(e) => setAssertionType(e.target.value)}>
          <MenuItem value={'body_assertion'}>Body</MenuItem>
          <MenuItem value={'header_assertion'}>Header</MenuItem>
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        label="Source Path"
        type="text"
        fullWidth
        variant="outlined"
        name="assertion-actual-value"
        value={assertionField}
        onChange={(e) => setAssertionField(e.target.value)}
      />
      <FormControl sx={{ minWidth: 550, my: '1rem' }}>
        <InputLabel>Operand</InputLabel>
        <Select
          label="Operand"
          defaultValue=""
          name="assertion-operand"
          value={assertionOperand}
          onChange={(e) => setAssertionOperand(e.target.value)}>
          {operandsList.map((operand, index) => {
            return (
              <MenuItem key={index} value={operand}>
                {operand}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        label="Value"
        type="text"
        fullWidth
        variant="outlined"
        multiline
        rows={4}
        name="assertion-expected-value"
        value={assertionValue}
        onChange={(e) => setAssertionValue(e.target.value)}
      />
    </Box>
  );
};

export default AddAssertionsModalContent;
