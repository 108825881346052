import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { TEST_TYPES } from '../../../utils/TestTypes';
import { Tooltip } from '@mui/material';

const TestConditionsForm = ({
  step,
  continueOnFailure,
  sslDisabled,
  retryCount,
  handleTestConditions,
  type
}) => {
  return (
    <Grid hidden={step != 4} sx={{ my: '2rem' }}>
      <FormControl hidden={false} sx={{ mr: '1rem', minWidth: 400 }}>
        <InputLabel>Retry Count</InputLabel>
        <Tooltip title={type === TEST_TYPES.BROWSER ? 'Feature Coming Soon...' : ''}>
          <Select
            disabled={type === TEST_TYPES.BROWSER}
            label="retry count"
            defaultValue={retryCount}
            onChange={(e) => handleTestConditions('retryCount', e.target)}>
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={1}>Once</MenuItem>
            <MenuItem value={2}>Twice</MenuItem>
            <MenuItem value={3}>Thrice</MenuItem>
          </Select>
        </Tooltip>
      </FormControl>
      {type === TEST_TYPES.API && (
        <>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={continueOnFailure}
                onChange={(e) => handleTestConditions('continueOnFailure', e.target)}
              />
            }
            label="Continue on Failure"
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={sslDisabled}
                onChange={(e) => handleTestConditions('sslDisabled', e.target)}
              />
            }
            label="Disable SSL"
          />
        </>
      )}
    </Grid>
  );
};

export default TestConditionsForm;
