import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const HeaderModalContent = ({ headerKey, setHeaderKey, headerValue, setHeaderValue }) => {
  return (
    <Box component="form" noValidate autoComplete="off" sx={{ my: '2rem' }}>
      <TextField
        sx={{ mb: '1rem' }}
        margin="dense"
        label="Key"
        type="text"
        fullWidth
        variant="outlined"
        value={headerKey}
        onChange={(e) => setHeaderKey(e.target.value)}
      />
      <TextField
        margin="dense"
        label="Value"
        type="text"
        fullWidth
        variant="outlined"
        multiline
        rows={4}
        value={headerValue}
        onChange={(e) => setHeaderValue(e.target.value)}
      />
    </Box>
  );
};

export default HeaderModalContent;
