import ProfileIcon from '../../assets/ProfileIcon.svg';
import {
  Grid,
  List,
  Menu,
  Stack,
  MenuItem,
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import './Header.css';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../store/slice/CommonSlice';

const Header = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(false);
  const loading = useSelector((state) => state.generalData.loading);
  const alert = useSelector((state) => state.generalData.alert);

  const handleClick = () => {
    setAnchorEl(true);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/'
    });
  };

  const handleAlertClose = () => {
    dispatch(commonActions.clearAlert());
  };

  const getSnackbarTimeoutMillis = (message) => {
    if (!message) {
      return 3000;
    }
    let wordCount = message.trim().split(/\s+/).length;
    return Math.max(wordCount * 200, 3000);
  };

  return (
    <>
      <Grid container className="header-root" data-testid="page-header">
        <Grid item xs={6}>
          <Link to={'/'}>
            <div className="header-logo"></div>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row-reverse" sx={{ mt: 1 }}>
            <List>
              <a
                className="bracket-icon"
                href={`${process.env.REACT_APP_SWAGGER_URL}`}
                target="_blank"
                rel="noreferrer">
                &emsp; &nbsp; API
              </a>
              <a
                className="bulb-icon"
                href="https://github.com/Maersk-Global/observability-the-pensieve/discussions"
                target="_blank"
                rel="noreferrer">
                &emsp; &nbsp; Discussion
              </a>
              <a
                className="support-icon"
                href="https://teams.microsoft.com/l/channel/19%3a9a9f7ecc18c84854ad338d9667aa52a6%40thread.tacv2/Support?groupId=f358a000-b2c3-4d58-b1e5-d3927ff9914b&tenantId=05d75c05-fa1a-42e7-9cf1-eb416c396f2d"
                target="_blank"
                rel="noreferrer">
                &emsp; &nbsp; Support
              </a>
              <a
                className="docs-icon"
                href="https://docs.mop.maersk.io/synthetic/"
                target="_blank"
                rel="noreferrer">
                &emsp; &nbsp; Documentation
              </a>
              <IconButton onClick={handleClick} sx={{ pl: 0, pb: 2, mr: 3 }}>
                <img src={ProfileIcon} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="profile-menu"
                open={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 5.5,
                    ml: -1.5,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 10,
                      width: 8,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </List>
          </Stack>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: 'rgb(30, 167, 253)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alert.show}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={getSnackbarTimeoutMillis(alert.message)}
        onClose={handleAlertClose}>
        <Alert
          variant="filled"
          severity={alert.type}
          sx={{
            width: '75vw',
            mt: '4rem',
            backgroundColor: alert.type === 'success' ? '#C5E5C2' : '#E9B2B7',
            color: 'black'
          }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Header;
