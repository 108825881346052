import { Grid, Button, Chip } from '@mui/material';
import { useState, useEffect } from 'react';
import RunTest from './testRun/RunTest';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteTest,
  deleteBrowserTest,
  fetchTestById,
  fetchBrowserTestById
} from '../../store/slice/test/TestActions';
import { fetchAllEnvironmentsInTestSuite } from '../../store/slice/environment/EnvironmentActions';
import CustomDataGrid from '../../components/table/CustomDataGrid';
import TestHistory from './TestHistoryTable';
import EditIcon from '../../assets/EditIcon.svg';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import ExportIcon from '../../assets/ExportIcon.svg';
import { commonActions } from '../../store/slice/CommonSlice';
import {
  HomeBreadCrumb,
  TestSuiteBreadCrumb,
  TestDetailsBreadCrumb
} from '../../utils/BreadCrumbUtils';
import AppBreadCrumb from '../../components/breadCrumbs/AppBreadCrumb';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { TEST_TYPES } from '../../utils/TestTypes';
import TestExportForm from '../createTest/createTestForms/TestExportForm';

const TestDetails = () => {
  const { suiteId, testId } = useParams();
  const path = window.location.pathname;
  const type = `${path.indexOf(TEST_TYPES.BROWSER)}` === '-1' ? TEST_TYPES.API : TEST_TYPES.BROWSER;
  const TestSuiteDetailsBreadCrumb = {
    link: `${window.location.pathname.split('/' + type, 1)}`,
    title: 'Test Suite Details'
  };
  const TestBreadCrumb = {
    link: `${window.location.pathname.split('/' + type, 1)}`,
    title: 'Tests'
  };
  const breadcrumbAr = [
    HomeBreadCrumb,
    TestSuiteBreadCrumb,
    TestSuiteDetailsBreadCrumb,
    TestBreadCrumb,
    TestDetailsBreadCrumb
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [envSchedules, setEnvSchedules] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openExportTestModal, setOpenExportTestModal] = useState(false);
  const test =
    type === TEST_TYPES.API
      ? useSelector((state) => state.testData.test)
      : useSelector((state) => state.testData.browserTest);
  const environments = useSelector((state) => state.environmentData.environments);
  const stepColumns = [
    { field: 'id', headerName: 'ID', hide: true, minWidth: 100, flex: 1, type: 'number' },
    { field: 'executionOrder', headerName: 'Order Id', hide: true, minWidth: 100, flex: 1 },
    { field: 'method', headerName: 'API', minWidth: 100, flex: 1 },
    { field: 'url', headerName: 'URL', minWidth: 100, flex: 1 }
  ];
  const scheduleColumns = [
    { field: 'id', headerName: 'Schedule ID', hide: true, minWidth: 100, flex: 1, type: 'number' },
    { field: 'environmentName', headerName: 'Environment', minWidth: 100, flex: 1 },
    { field: 'duration', headerName: 'Frequency', minWidth: 100, flex: 1 }
  ];

  useEffect(() => {
    type === TEST_TYPES.BROWSER
      ? dispatch(fetchBrowserTestById(suiteId, testId))
      : dispatch(fetchTestById(suiteId, testId));
    dispatch(fetchAllEnvironmentsInTestSuite(suiteId));
  }, []);

  useEffect(() => {
    if (test.schedules !== undefined) {
      let temp = JSON.parse(JSON.stringify(test.schedules));
      temp.forEach((schedule) => {
        schedule.duration = schedule.cron
          ? schedule.cronExpression
          : 'Every ' + schedule.duration + ' s';
      });
      setEnvSchedules(temp);
    }
  }, [test]);

  const onDeleteTestBtnClick = () => {
    setOpenDeleteModal(true);
  };

  const onDeleteTestBtnClose = () => {
    setOpenDeleteModal(false);
  };

  const onDeleteTest = () => {
    dispatch(commonActions.showLoadingIcon(true));
    const deleteAction =
      type === TEST_TYPES.BROWSER
        ? deleteBrowserTest(test.testSuiteId, test.id)
        : deleteTest(test.testSuiteId, test.id);
    dispatch(deleteAction).then(
      () => {
        setOpenDeleteModal(false);
        dispatch(commonActions.showSuccessAlert(`Test ${test.name} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        navigate(TestSuiteDetailsBreadCrumb.link);
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
      }
    );
  };

  const handleTestUpdate = () => {
    type === TEST_TYPES.API
      ? navigate(`/suites/${suiteId}/${TEST_TYPES.API}/${testId}/update`)
      : navigate(`/suites/${suiteId}/${TEST_TYPES.BROWSER}/${testId}/update`);
  };

  const handleTestExport = () => {
    setOpenExportTestModal(true);
  };

  return (
    <Grid container>
      <AppBreadCrumb data={breadcrumbAr} />
      <Grid container mx="200px" mt="50px">
        <Grid container>
          <Grid item xs={10}>
            <Grid item xs={12} className="label">
              TEST DETAILS
            </Grid>
            <Grid item xs={12} mt={2} className="heading_team">
              {test.name}
            </Grid>
            <Grid item xs={12} mt={2} className="description">
              {test.description}
            </Grid>
            <Grid item xs={12} ml={-2} mt={1}>
              <Button variant="text" onClick={handleTestUpdate}>
                <img src={EditIcon} />
                Edit
              </Button>
              <Button variant="text" onClick={onDeleteTestBtnClick}>
                <img src={DeleteIcon} />
                Delete
              </Button>
              <Button variant="text" onClick={handleTestExport}>
                <img src={ExportIcon} />
                Export
              </Button>
            </Grid>
          </Grid>
          <TestExportForm
            open={openExportTestModal}
            width="xs"
            title="Export Test"
            jsonData={JSON.stringify(test, null, 2)}
            setOpenExportTestModal={setOpenExportTestModal}
            test={test}
          />
          <Grid item xs={2}>
            <RunTest environments={environments} test={test} type={type} />
          </Grid>
        </Grid>
        <Grid container sx={{ minHeight: '57vh' }}>
          <Grid
            container
            pt={3}
            pb={5}
            px={5}
            my={4}
            sx={{ background: 'rgba(191, 215, 221, 0.2)', borderRadius: '8px' }}>
            <Grid item xs={6}>
              <Grid item xs={12} px={5} pb={2} fontSize={20}>
                {type === TEST_TYPES.API ? 'Steps' : 'Starting URL'}
              </Grid>
              {type === TEST_TYPES.API ? (
                <CustomDataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'executionOrder' }]
                    }
                  }}
                  rows={test.steps ? test.steps : []}
                  columns={stepColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  autoHeight
                  disableSelectionOnClick
                  hideFooter={envSchedules.length > 5 ? false : true}
                />
              ) : (
                <Grid sx={{ ml: 5 }}>
                  <Grid
                    sx={{
                      background: '#fff',
                      p: 2,
                      width: '90%',
                      border: '1px solid rgba(224, 224, 224, 1)'
                    }}>
                    {test.startingUrl}
                  </Grid>
                  <Grid py={2} fontSize={20}>
                    {type !== TEST_TYPES.API && 'Device Browser Mappings'}
                  </Grid>
                  {test.browserDeviceMappings?.map((pair, index) => (
                    <Chip
                      sx={{
                        mr: 1,
                        background: '#fff',
                        border: '1px solid rgba(224, 224, 224, 1)'
                      }}
                      size="large"
                      key={index}
                      label={pair.deviceType + ' - ' + pair.browserType}
                    />
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} px={5} pb={2} fontSize={20}>
                Schedules
              </Grid>
              <CustomDataGrid
                rows={envSchedules}
                columns={scheduleColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                autoHeight
                disableSelectionOnClick
                hideFooter={envSchedules.length > 5 ? false : true}
              />
            </Grid>
          </Grid>
          <TestHistory type={type} />
        </Grid>
        <ConfirmDialog
          open={openDeleteModal}
          width="xs"
          title="Delete Test"
          handleSave={onDeleteTest}
          saveText="Delete"
          handleClose={onDeleteTestBtnClose}>
          Are you sure you want to delete this test? This cannot be undone after it is deleted.
        </ConfirmDialog>
      </Grid>
    </Grid>
  );
};
export default TestDetails;
