import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  variables: [],
  variable: {
    id: '',
    name: '',
    description: '',
    secret: false
  }
};
export const variableSlice = createSlice({
  name: 'variableSlice',
  initialState: initialState,
  reducers: {
    updateVariableList(state, action) {
      state.variables = action.payload;
    },
    updateVariable(state, action) {
      state.variable = action.payload;
    },
    resetVariable(state) {
      state.variable = initialState.variable;
    }
  }
});

export const variableActions = variableSlice.actions;
