import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '../../../../../../assets/EditIcon.svg';
import DeleteIcon from '../../../../../../assets/DeleteIcon.svg';

const ExtractVariablesTable = ({
  extractVariablesList,
  setFormStateOnEdit,
  handleDeleteExtractVariable
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Source</TableCell>
            <TableCell>Source Path</TableCell>
            <TableCell colSpan={2}>Variable Name</TableCell>
            {/* <TableCell colSpan={2}>Global</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {extractVariablesList &&
            extractVariablesList.map((variable, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{variable.source}</TableCell>
                  <TableCell>{variable.sourcePath}</TableCell>
                  <TableCell>{variable.destinationVariableName}</TableCell>
                  {/* <TableCell>
                    <Checkbox checked={variable.global} />
                  </TableCell> */}
                  <TableCell align="right">
                    <IconButton>
                      <img src={EditIcon} onClick={() => setFormStateOnEdit(index, variable)} />
                    </IconButton>
                    <IconButton>
                      <img
                        src={DeleteIcon}
                        onClick={() => handleDeleteExtractVariable(index, variable)}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExtractVariablesTable;
