import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import Button from '@mui/material/Button';
import { Dialog, Grid, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import StaticHomePage from '../../assets/StaticHomePage.png';
import MadEyeLogo from '../../assets/MadEyeLogo.svg';
import LockIcon from '../../assets/LockIcon.svg';

export const LoginPage = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${StaticHomePage})`,
        height: '100vh',
        backgroundSize: 'cover',
        filter: 'blur(2px)'
      }}>
      <Dialog
        open
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '550px'
            }
          }
        }}>
        <DialogTitle sx={{ mx: 'auto' }}>
          <img src={MadEyeLogo} style={{ padding: '20px' }} />
          <div style={{ justifyContent: 'center', display: 'flex' }}>Welcome to</div>
        </DialogTitle>
        <DialogContent sx={{ mx: 'auto' }}>
          <div style={{ justifyContent: 'center', display: 'flex', fontSize: '30px' }}>
            Maersk Observability Platform
          </div>
          <div style={{ fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
            Synthetic Monitoring
          </div>
        </DialogContent>
        <br />
        <DialogActions sx={{ background: '#F7F7F7', py: '15px' }}>
          <Button
            variant="contained"
            sx={{ mx: 'auto' }}
            endIcon={<img src={LockIcon} />}
            onClick={() => handleLogin()}>
            Log-in using SSO
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
