import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SwapVert from '@mui/icons-material/SwapVert';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import EditIcon from '../../../../assets/EditIcon.svg';
import DeleteIcon from '../../../../assets/DeleteIcon.svg';
import { Tooltip } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const StepList = ({ stepsList, handleEditStep, handleDeleteStep, handleStepDrop }) => {
  if (stepsList.length > 1) {
    stepsList = stepsList
      .slice()
      .sort((item1, item2) => item1.executionOrder - item2.executionOrder);
  }
  return (
    <DragDropContext onDragEnd={handleStepDrop}>
      <Droppable droppableId={'steps-container'}>
        {(provided) => (
          <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
            <Grid sx={{ mt: '2rem' }}>
              <Grid sx={{ backgroundColor: 'lightgray', p: '.5rem' }}>Steps</Grid>
              <List>
                {stepsList.map((step, index) => {
                  const id = step.id ? step.id : step.localID;
                  return (
                    <Draggable key={id} draggableId={id.toString()} index={index}>
                      {(provided) => (
                        <div
                          className="item-container"
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}>
                          <ListItem
                            key={index}
                            sx={{
                              borderRadius: 1,
                              backgroundColor: 'gray',
                              display: 'flex',
                              justifyContent: 'space-between',
                              my: '1rem'
                            }}>
                            <Grid>
                              {index + 1}
                              <SwapVert />
                            </Grid>
                            <Grid
                              sx={{
                                display: 'flex',
                                flexGrow: 1,
                                justifyContent: 'space-between',
                                backgroundColor: 'white',
                                p: '.5rem',
                                mx: '1rem'
                              }}>
                              <Tooltip title={step.name}>
                                <Grid xs={3}>
                                  {step.name.length > 20
                                    ? step.name.substring(0, 20) + '...'
                                    : step.name}
                                </Grid>
                              </Tooltip>
                              <Grid xs={1}>{step.method}</Grid>
                              <Tooltip title={step.url}>
                                <Grid xs={5}>
                                  {step.url.length > 30
                                    ? step.url.substring(0, 30) + '...'
                                    : step.url}
                                </Grid>
                              </Tooltip>
                            </Grid>
                            <Grid>
                              <IconButton>
                                <img src={EditIcon} onClick={() => handleEditStep(id)} />
                              </IconButton>
                              <IconButton>
                                <img src={DeleteIcon} onClick={() => handleDeleteStep(id)} />
                              </IconButton>
                            </Grid>
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            </Grid>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default StepList;
