import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import CustomDataGrid from '../../components/table/CustomDataGrid';
import { fetchTestResultHistory } from '../../store/slice/test/TestActions';
import moment from 'moment';
import { Grid, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const TestHistory = (props) => {
  const { suiteId, testId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [resultData, setResultData] = useState({});
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 5
  });

  const columns = [
    { field: 'id', headerName: 'ID', hide: true, minWidth: 100, flex: 1, type: 'number' },
    {
      field: 'updatedTimestamp',
      headerName: 'Date',
      minWidth: 100,
      flex: 1,
      type: 'date',
      valueFormatter: (params) => {
        let utcDatetime = params?.value?.replace('UTC', '').trim();
        return moment.utc(utcDatetime).local().format('DD MMM YY, HH:mm:ss');
      }
    },
    {
      field: 'environmentDTO',
      headerName: 'Environment',
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => {
        return params?.value?.name;
      }
    },
    { field: 'location', headerName: 'Location', minWidth: 100, flex: 1 },
    { field: 'domain', headerName: 'Domain', minWidth: 100, flex: 1 },
    {
      field: 'testRunDuration',
      headerName: 'Duration',
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => {
        return params.value + ' ms';
      }
    },
    {
      field: 'result',
      headerName: 'Status',
      maxWidth: 150,
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <>
            <Grid
              height={15}
              width={15}
              borderRadius="50%"
              mr={1}
              sx={{
                backgroundColor: 'green'
              }}></Grid>
            Success
          </>
        ) : (
          <>
            <Grid
              height={15}
              width={15}
              borderRadius="50%"
              mr={1}
              sx={{
                backgroundColor: 'red'
              }}></Grid>
            Failed
          </>
        )
    },
    {
      field: 'edit',
      type: 'actions',
      maxWidth: 100,
      getActions: (params) => {
        return [
          <IconButton key="view" onClick={(e) => handleViewRowData(e, params)}>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        ];
      }
    }
  ];

  useEffect(() => {
    dispatch(
      fetchTestResultHistory(
        props.type,
        suiteId,
        testId,
        pageState.page,
        pageState.pageSize,
        setPageState
      )
    );
  }, [pageState.page, pageState.pageSize]);

  const handleViewRowData = (_e, params) => {
    setShowResult(true);
    setResultData(params.row);
  };

  useEffect(() => {
    window.location.pathname.includes('/result/') && setShowResult(true);
  }, []);

  return (
    <Grid
      container
      mb={5}
      pt={3}
      pb={5}
      sx={{ background: 'rgba(191, 215, 221, 0.2)', borderRadius: '8px' }}>
      <Grid container>
        <Grid item xs={12} px={10} pb={2} fontSize={20}>
          Test Results
        </Grid>
        <Grid item xs={12}>
          <CustomDataGrid
            rows={pageState.data}
            rowCount={pageState.total}
            loading={pageState.isLoading}
            columns={columns}
            pageSize={pageState.pageSize}
            rowsPerPageOptions={[5]}
            autoHeight
            disableSelectionOnClick
            pagination
            page={pageState.page}
            paginationMode="server"
            onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage }))}
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({ ...old, pageSize: newPageSize }))
            }
          />
        </Grid>
      </Grid>
      <Outlet context={[showResult, setShowResult]} />
      {showResult &&
        !window.location.pathname.includes('/result/') &&
        navigate(`${window.location.pathname}/result/${resultData.id}`)}
    </Grid>
  );
};
export default TestHistory;
