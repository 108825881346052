import { Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomDataGrid from '../../components/table/CustomDataGrid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '../../assets/DeleteIcon.svg';

const TeamMemberTable = (props) => {
  const { user, teamMembers, isAdmin } = props;

  const columns = [
    { field: 'id', headerName: 'ID', hide: true, minWidth: 100, flex: 1, type: 'number' },
    { field: 'userId', headerName: 'Username', minWidth: 100, flex: 1 },
    {
      field: 'userRole',
      headerName: 'Role',
      minWidth: 100,
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['ADMIN', 'WRITER']
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => {
        if (params.row.userId != user.email) {
          return [
            <IconButton
              key="delete"
              onClick={(e) => props.onDeleteTeamMemberBtnClick(e, params.row.userId)}
              disabled={!isAdmin}>
              <img src={DeleteIcon} />
            </IconButton>
          ];
        } else {
          return [];
        }
      }
    }
  ];
  return (
    <Grid item xs={12} id="team-member-table" sx={{ marginBottom: '2rem' }}>
      {teamMembers != undefined && (
        <>
          <Grid sx={{ margin: 'auto' }} container width="90%" className="table-container-position">
            <Grid item xs={6}>
              Team members
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{ float: 'right', margin: '0px' }}
                onClick={props.onEditTeamBtnClick}
                disabled={!isAdmin}>
                Add Members
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
          <CustomDataGrid
            className="table"
            rows={teamMembers}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            autoHeight
            disableSelectionOnClick
          />
        </>
      )}
    </Grid>
  );
};
export default TeamMemberTable;
