export const HomeBreadCrumb = {
  link: '/',
  title: 'Home'
};

export const TestSuiteBreadCrumb = {
  link: '/',
  title: 'Test Suites'
};

export const TeamsBreadCrumb = {
  link: '/',
  title: 'Teams'
};

export const TeamDetailsBreadCrumb = {
  title: 'Team Details'
};

export const TestSuiteDetailsBreadCrumb = {
  title: 'Test Suite Details'
};

export const EnvironmentDetailsBreadCrumb = {
  title: 'Environment Details'
};

export const TestDetailsBreadCrumb = {
  title: 'Test Details'
};
