import { Chip, Grid, List, ListItem, Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import AssertionResult from './AssertionResult';
import { TEST_TYPES } from '../../../utils/TestTypes';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import axiosInstance from '../../../services/axios';
import { commonActions } from '../../../store/slice/CommonSlice';
import { useDispatch } from 'react-redux';

const TestRunDetails = (props) => {
  const dots = ' . '.repeat(100);
  const dispatch = useDispatch();

  const openFailureScreenshotInNewTab = (deviceType, browserType, index) => {
    const result = props.testResults[index];
    axiosInstance({
      method: 'get',
      url: `/v1/test_suites/${result.testSuiteId}/browser_tests/${result.browserTestDTO.id}/results/${result.id}/file_download?deviceType=${deviceType}&browserType=${browserType}`,
      responseType: 'blob'
    })
      .then((blob) => {
        const imageUrl = URL.createObjectURL(blob.data);
        window.open(imageUrl, '_blank');
      })
      .catch((error) => {
        dispatch(commonActions.showErrorAlert('Error fetching image'));
        console.log('Error fetching image', error);
      });
  };

  const getScriptLines = (testResult) => {
    return testResult?.browserTestDTO?.script || props?.test?.script;
  };

  return (
    <Grid sx={{ mt: '2rem' }}>
      {props.testResults &&
        props.testResults.map((testResult, index) => (
          <Accordion defaultExpanded={!props.multiLocation} key={index} sx={{ mb: 2 }}>
            <AccordionSummary
              sx={{ backgroundColor: '#DBDBDB' }}
              expandIcon={<ExpandMoreIcon sx={{ color: 'black', mr: 1.5 }} />}>
              <Grid container xs={5.5}>
                <Grid mr={1}>
                  {testResult.result ? (
                    <CheckCircleOutlineIcon color="success" fontSize="large" />
                  ) : (
                    <ReportGmailerrorredIcon color="error" fontSize="large" />
                  )}{' '}
                </Grid>
                <Grid>
                  <Grid>
                    <b>Status</b>
                  </Grid>
                  <Grid>{testResult.result ? 'Success' : 'Failed'}</Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid>
                  <Grid>
                    <b>Location</b>
                  </Grid>
                  <Grid>{testResult.location}</Grid>
                </Grid>
                <Grid>
                  <Grid>
                    <b>Domain</b>
                  </Grid>
                  <Grid>{testResult.domain}</Grid>
                </Grid>
                <Grid mr={1}>
                  <Grid>
                    <b>Duration</b>
                  </Grid>
                  <Grid>{testResult.testRunDuration} ms</Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: 'rgba(191, 215, 221, 0.4)' }}>
              <List>
                <b>{props.type === TEST_TYPES.API ? 'Steps' : ''}</b>
                {props.type === TEST_TYPES.API
                  ? testResult.stepResults &&
                    testResult.stepResults.map((item, index) => {
                      return (
                        <Grid key={index} sx={{ m: '1rem' }}>
                          <Accordion>
                            <AccordionSummary
                              sx={{ background: '#545454', p: 0 }}
                              expandIcon={<ExpandMoreIcon sx={{ color: 'white', mr: 1.5 }} />}>
                              <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Grid sx={{ color: 'white' }}>{index + 1}</Grid>
                                <Grid
                                  sx={{
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexGrow: 1,
                                    justifyContent: 'space-between',
                                    backgroundColor: 'white',
                                    py: '.5rem',
                                    px: '1rem',
                                    mx: '1rem'
                                  }}>
                                  <Tooltip title={item.stepName}>
                                    <Grid>
                                      {item.stepName.length > 20
                                        ? item.stepName.substring(0, 20) + '...'
                                        : item.stepName}
                                    </Grid>
                                  </Tooltip>
                                  <Grid>
                                    {item.stepMethod} &nbsp;
                                    <Tooltip title={item.stepUrl}>
                                      <span>
                                        {item.stepUrl.length > 50
                                          ? item.stepUrl.substring(0, 50) + '...'
                                          : item.stepUrl}
                                      </span>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                                <Chip
                                  size="small"
                                  color={item.result ? 'success' : 'error'}
                                  label={item.responseStatus}
                                />
                              </ListItem>
                            </AccordionSummary>
                            <AccordionDetails sx={{ background: '#545454' }}>
                              <AssertionResult
                                assertions={item.assertionResults}
                                responseTime={item.responseTime}
                                error={item.error}
                                pauseDuration={item.pauseDuration}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      );
                    })
                  : testResult.browserTestResultDetailsDTOList &&
                    testResult.browserTestResultDetailsDTOList.map((item, i) => {
                      return (
                        <Grid key={i} sx={{ m: '1rem' }}>
                          <Accordion>
                            <AccordionSummary
                              sx={{ background: '#545454', p: 0 }}
                              expandIcon={<ExpandMoreIcon sx={{ color: 'white', mr: 1.5 }} />}>
                              <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Grid sx={{ color: 'white' }}>{i + 1}</Grid>
                                <Grid
                                  sx={{
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexGrow: 1,
                                    justifyContent: 'space-between',
                                    backgroundColor: 'white',
                                    py: '.5rem',
                                    px: '1rem',
                                    mx: '1rem'
                                  }}>
                                  <Grid>{item?.deviceType}</Grid>
                                  <Grid>{item?.browserType}</Grid>
                                  <Grid>{item?.duration} ms</Grid>
                                </Grid>
                                {!item?.result && (
                                  <Chip
                                    sx={{ mr: 1.5 }}
                                    size="small"
                                    color="error"
                                    label="Failure Screenshot"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      openFailureScreenshotInNewTab(
                                        item?.deviceType,
                                        item?.browserType,
                                        index
                                      );
                                    }}
                                    onDelete={(e) => {
                                      e.preventDefault();
                                      openFailureScreenshotInNewTab(
                                        item?.deviceType,
                                        item?.browserType,
                                        index
                                      );
                                    }}
                                    deleteIcon={<ArrowOutwardIcon />}
                                  />
                                )}
                                <Chip
                                  size="small"
                                  color={item?.result ? 'success' : 'error'}
                                  label={item?.result ? 'Passed' : 'Failed'}
                                />
                              </ListItem>
                            </AccordionSummary>
                            <AccordionDetails sx={{ background: 'black', color: 'white', p: 3 }}>
                              {item.result
                                ? getScriptLines(testResult)
                                    .split(';')
                                    .map((line, index, lines) => (
                                      <Grid key={index} sx={{ display: 'flex' }}>
                                        <Grid
                                          sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                          }}
                                          key={index}>
                                          {line.trim()}
                                          {index < lines.length - 1 && dots}
                                        </Grid>
                                        {index < lines.length - 1 && (
                                          <DoneIcon
                                            color="success"
                                            style={{ verticalAlign: 'middle' }}
                                          />
                                        )}
                                      </Grid>
                                    ))
                                : getScriptLines(testResult)
                                    .split(';')
                                    .map((line, index) => (
                                      <Grid key={index} sx={{ display: 'flex' }}>
                                        <Grid
                                          sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                          }}
                                          key={index}>
                                          {index < item?.error?.row - 2 && line.trim()}
                                          {index < item?.error?.row - 2 && dots}
                                        </Grid>
                                        {index < item?.error?.row - 3 && (
                                          <DoneIcon color="success" />
                                        )}
                                        {index === item?.error?.row - 3 && (
                                          <ClearIcon color="error" />
                                        )}
                                      </Grid>
                                    ))}
                              {!item.result && (
                                <Grid sx={{ mt: 2, p: 1, background: 'black' }}>
                                  Error : {item.error?.message}
                                </Grid>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      );
                    })}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      {props.testResults.length === 0 && (
        <Grid container mx={35} my={20}>
          Result not found! Please run again.
        </Grid>
      )}
      {props.failedRunIds && props.failedRunIds.length > 0 && (
        <Grid container mx={35} my={3}>
          Results not found for all locations! Please run again.
        </Grid>
      )}
    </Grid>
  );
};
export default TestRunDetails;
