import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import AssertionsTable from './AssertionsTable';
import StepDetailsModalWrapper from '../StepDetailsModalWrapper';
import AddAssertionsModalContent from './AddAssertionsModalContent';
import { useParams } from 'react-router-dom';

const AddAssertionsForm = ({
  setOpenAssertionsModal,
  openAssertionsModal,
  operandsList,
  assertionType,
  setAssertionType,
  assertionField,
  setAssertionField,
  assertionOperand,
  setAssertionOperand,
  assertionValue,
  setAssertionValue,
  handleAddAssertion,
  handleDeleteAssertion,
  edit,
  setEdit,
  assertionsList
}) => {
  const [assertionToEdit, setAssertionToEdit] = useState('');
  const [editStep, setEditstep] = useState(false);
  const { testId } = useParams();
  const setFormStateOnEdit = (assertion) => {
    setAssertionType(assertion.assertionType);
    setAssertionField(assertion.actualValue);
    setAssertionOperand(assertion.operator);
    setAssertionValue(assertion.expectedValue);

    setEdit(true);
    setOpenAssertionsModal(true);

    const id = assertion.localID ? assertion.localID : assertion.id;

    setAssertionToEdit(id);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    testId && editStep && setEdit(true);
    testId && !editStep && setEdit(false);
    !testId && editStep && setEdit(true);
    !testId && !editStep && setEdit(false);
    setAssertionType('');
    setAssertionField('');
    setAssertionOperand('');
    setAssertionValue('');
    setOpenAssertionsModal(false);
  };

  useEffect(() => {
    setEditstep(edit);
  }, []);

  return (
    <>
      <Grid sx={{ display: 'flex', justifyContent: 'end', mb: '1rem' }}>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            setEdit(false);
            setOpenAssertionsModal(true);
          }}>
          Add Assertion
          <AddIcon />
        </Button>
        {openAssertionsModal && (
          <StepDetailsModalWrapper
            open={openAssertionsModal}
            edit={edit}
            disabled={!assertionType || !assertionOperand || !assertionField || !assertionValue}
            title="Assertion"
            handleSubmit={(e) => handleAddAssertion(e, assertionToEdit, editStep)}
            handleCancel={(e) => handleCancel(e)}>
            <AddAssertionsModalContent
              operandsList={operandsList}
              assertionType={assertionType}
              setAssertionType={setAssertionType}
              assertionField={assertionField}
              setAssertionField={setAssertionField}
              assertionOperand={assertionOperand}
              setAssertionOperand={setAssertionOperand}
              assertionValue={assertionValue}
              setAssertionValue={setAssertionValue}
            />
          </StepDetailsModalWrapper>
        )}
      </Grid>
      <AssertionsTable
        assertionsList={assertionsList}
        setFormStateOnEdit={setFormStateOnEdit}
        handleDeleteAssertion={handleDeleteAssertion}
      />
    </>
  );
};

export default AddAssertionsForm;
