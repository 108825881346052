import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { AVAILABLE_LOCATIONS } from '../../utils/AvailableLocations';
import axiosInstance from '../../services/axios';

const CreateEnvironmentModal = (props) => {
  const { suiteId } = useParams();
  const [environment, setEnvironment] = useState({
    testSuiteId: suiteId,
    locations: ['West Europe'],
    alertWebhook: ''
  });
  const [disableSave, setDisableSave] = useState(true);
  const [envNameOptions, setEnvNameOptions] = useState([]);

  const handleReset = (environment) => {
    setEnvironment({
      ...environment,
      name: '',
      description: '',
      locations: ['West Europe']
    });
  };

  const handleSave = () => {
    props.handleSave(environment);
    handleReset(environment);
  };

  const handleClose = () => {
    props.handleClose();
  };

  useEffect(async () => {
    await axiosInstance({
      method: 'get',
      url: '/v1/ui/environments'
    })
      .then((response) => {
        setEnvNameOptions(response.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  useEffect(() => {
    if (props.environment != undefined) {
      setEnvironment({
        name: props.environment.name,
        description: props.environment.description,
        locations: props.environment.locations
      });
    }
  }, [props.environment]);

  useEffect(() => {
    const disable = !environment.name || !environment.description || !environment.locations.length;
    setDisableSave(disable);
  }, [environment]);

  return (
    <ConfirmDialog
      open={props.open}
      width={props.width}
      title={props.title}
      saveText={props.title == 'Create New Environment' ? 'Create Environment' : null}
      handleSave={handleSave}
      handleClose={handleClose}
      disableSave={disableSave}>
      <Autocomplete
        required
        margin="dense"
        data-testid="create-environment-name"
        disabled={props.environment}
        options={envNameOptions}
        isOptionEqualToValue={(option, value) => option === value}
        renderInput={(params) => <TextField {...params} label="Select Environment Name" />}
        defaultValue={props.environment ? props.environment.name : ''}
        onChange={(event, newValue) => setEnvironment({ ...environment, name: newValue })}
        sx={{ mt: 1 }}
      />
      <TextField
        margin="dense"
        data-testid="create-environment-desc"
        multiline
        required
        rows={4}
        label="Description"
        fullWidth
        variant="outlined"
        defaultValue={props.environment ? props.environment.description : ''}
        onChange={(e) => setEnvironment({ ...environment, description: e.target.value })}
        sx={{ my: 2 }}
      />
      <Autocomplete
        data-testid="create-location-combo-box-environment"
        multiple
        options={AVAILABLE_LOCATIONS}
        required
        isOptionEqualToValue={(option, value) => option === value}
        renderInput={(params) => <TextField {...params} label="Select Location" />}
        defaultValue={props.environment ? props.environment.locations : ['West Europe']}
        onChange={(event, newValue) => {
          event.preventDefault;
          setEnvironment({
            ...environment,
            locations: newValue
          });
        }}
      />
    </ConfirmDialog>
  );
};
export default CreateEnvironmentModal;
