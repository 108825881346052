import { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import HeadersTable from './HeadersTable';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import StepDetailsModalWrapper from '../StepDetailsModalWrapper';
import HeaderModalContent from './HeaderModalContent';

const MetaDataForm = ({ headers, setHeaders, body, setBody, contentType, setContentType }) => {
  const [openHeaderModal, setopenHeaderModal] = useState(false);

  const [headerKey, setHeaderKey] = useState('');
  const [headerValue, setHeaderValue] = useState('');
  const [edit, setEdit] = useState(false);
  const [objectToEdit, setObjectToEdit] = useState({});

  const setHeaderFormOnEdit = (e, key, value) => {
    setHeaderKey(key);
    setHeaderValue(value);

    setEdit(true);
    setopenHeaderModal(true);
    setObjectToEdit({ [key]: value });
  };

  const handleHeaders = (e, header, deleteEntry) => {
    const reset = () => {
      setHeaderKey('');
      setHeaderValue('');
      setopenHeaderModal(false);
    };

    if (edit === true) {
      const key = Object.keys(header)[0];
      const copy = Object.assign({}, header);
      delete copy[key];

      setHeaders((copy) => {
        return { ...copy, [headerKey]: headerValue };
      });
      setEdit(false);
      setObjectToEdit({});
      reset();
    } else if (deleteEntry === true) {
      setHeaders((headers) => {
        const copy = { ...headers };
        delete copy[header[0]];
        return copy;
      });
    } else {
      setHeaders((headers) => {
        return { ...headers, [headerKey]: headerValue };
      });
      reset();
    }
  };

  const handleContentTypeSelect = (selected) => {
    setBody('');
    setContentType(selected);
    if (selected != 'raw') {
      setHeaders((headers) => {
        let contentTypeKey =
          Object.keys(headers).find((key) => key.toLowerCase() === 'content-type') ||
          'content-type';
        return { ...headers, [contentTypeKey]: selected };
      });
    } else {
      setHeaders((headers) => {
        let contentTypeKey = Object.keys(headers).find(
          (key) => key.toLowerCase() === 'content-type'
        );
        const copy = { ...headers };
        delete copy[contentTypeKey];
        return copy;
      });
    }
  };

  return (
    <>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} scroll="paper">
        <Typography variant="h6">Headers</Typography>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            setopenHeaderModal(true);
          }}>
          Add Property
          <AddIcon />
        </Button>
        {/* This is the header modal */}
        {openHeaderModal && (
          <StepDetailsModalWrapper
            open={openHeaderModal}
            title="Header"
            edit={edit}
            objectToEdit={objectToEdit}
            handleSubmit={handleHeaders}
            handleCancel={(e) => {
              e.preventDefault();
              setopenHeaderModal(false);
            }}>
            <HeaderModalContent
              headerKey={headerKey}
              setHeaderKey={setHeaderKey}
              headerValue={headerValue}
              setHeaderValue={setHeaderValue}
            />
          </StepDetailsModalWrapper>
        )}
      </Grid>
      <HeadersTable
        headers={headers}
        setHeaderFormOnEdit={setHeaderFormOnEdit}
        handleHeaders={handleHeaders}
      />

      <FormControl sx={{ my: '1rem', minWidth: 400 }}>
        <InputLabel>Content Type</InputLabel>
        <Select
          label="content type"
          value={contentType === 'application/x-www-form-urlencoded' ? contentType : 'raw'}
          onChange={(e) => {
            handleContentTypeSelect(e.target.value);
          }}>
          <MenuItem value={'raw'}>raw</MenuItem>
          <MenuItem value={'application/x-www-form-urlencoded'}>x-www-form-urlencoded</MenuItem>
        </Select>
      </FormControl>
      <Grid style={{ width: '50ch', my: '1rem' }}>
        <TextField
          margin="dense"
          type="text"
          fullWidth
          variant="outlined"
          label="Request body"
          multiline
          rows={4}
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </Grid>
    </>
  );
};

export default MetaDataForm;
