import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '../../../../../../assets/EditIcon.svg';
import DeleteIcon from '../../../../../../assets/DeleteIcon.svg';

const AssertionsTable = ({ assertionsList, setFormStateOnEdit, handleDeleteAssertion }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Assertion Type</TableCell>
            <TableCell>Source Path</TableCell>
            <TableCell>Operand</TableCell>
            <TableCell colSpan={2}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assertionsList !== null &&
            assertionsList.map((assertion, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{assertion.assertionType}</TableCell>
                  <TableCell>{assertion.actualValue}</TableCell>
                  <TableCell>{assertion.operator}</TableCell>
                  <TableCell>{assertion.expectedValue}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => setFormStateOnEdit(assertion)}>
                      <img src={EditIcon} />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteAssertion(assertion)}>
                      <img src={DeleteIcon} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssertionsTable;
