import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../../store/slice/CommonSlice';
import { TEST_TYPES } from '../../../utils/TestTypes';
import ConfirmDialog from '../../../components/modal/ConfirmDialog';
import {
  createTest,
  createBrowserTest,
  fetchAllTestsInTestSuite,
  fetchAllBrowserTestsInTestSuite
} from '../../../store/slice/test/TestActions';

const TestImportForm = (props) => {
  const [jsonInput, setJsonInput] = useState('');
  const { suiteId } = useParams();
  const dispatch = useDispatch();
  const [responseMessage, setResponseMessage] = useState('');
  const [name, setName] = useState('');
  const [invalidJson, setInvalidJson] = useState(true);

  const handleClose = () => {
    setJsonInput('');
    setName('');
    setResponseMessage('');
    props.setOpenImportTestModal(false);
  };

  const handleNameUpdate = (name) => {
    try {
      setName(name);
      const test = JSON.parse(jsonInput);
      test.name = name;
      setJsonInput(JSON.stringify(test, null, 2));
    } catch (error) {
      console.log('failed to parse JSON');
    }
  };

  const handleInput = (json) => {
    setResponseMessage('');
    setJsonInput(json);
    setInvalidJson(false);
    if (json.trim() !== '') {
      try {
        const test = JSON.parse(json);
        if (name !== '') {
          test.name = name;
        }
        test.testSuiteId = suiteId;
        setJsonInput(JSON.stringify(test, null, 2));
      } catch (error) {
        console.log('failed to parse JSON');
        setInvalidJson(true);
        setResponseMessage('Please enter valid Test JSON');
      }
    }
  };

  const handleLoad = () => {
    try {
      const parsedJson = JSON.parse(jsonInput);
      parsedJson.name = name;
      parsedJson.schedules = [];
      parsedJson.testSuiteId = suiteId;
      parsedJson.alertCondition = null;
      parsedJson.alertWebhook = '';
      const type = Object.hasOwnProperty.call(parsedJson, 'script') ? 'browser-tests' : 'tests';
      const loadAction =
        type === TEST_TYPES.BROWSER
          ? createBrowserTest(suiteId, parsedJson)
          : createTest(suiteId, parsedJson);
      dispatch(loadAction).then(
        () => {
          dispatch(commonActions.showSuccessAlert(`Test ${parsedJson.name} Created`));
          dispatch(commonActions.showLoadingIcon(false));
          dispatch(fetchAllTestsInTestSuite(suiteId));
          dispatch(fetchAllBrowserTestsInTestSuite(suiteId));
          setJsonInput('');
          setResponseMessage('');
          props.setOpenImportTestModal(false);
          setName('');
        },
        function (error) {
          dispatch(commonActions.showLoadingIcon(false));
          dispatch(fetchAllTestsInTestSuite(suiteId));
          dispatch(fetchAllBrowserTestsInTestSuite(suiteId));
          dispatch(commonActions.showErrorAlert(error.response.data.message));
          setJsonInput('');
          setResponseMessage('');
          props.setOpenImportTestModal(false);
          setName('');
        }
      );
    } catch (error) {
      console.log('failed to parse JSON');
      setInvalidJson(true);
      setResponseMessage('Please enter valid Test JSON');
    }
  };
  return (
    <ConfirmDialog
      open={props.open}
      width={props.width}
      title={props.title}
      saveText="Import"
      handleSave={handleLoad}
      handleClose={handleClose}
      disableSave={name.length == 0 || jsonInput.length == 0 || invalidJson}>
      <Grid container>
        <TextField
          sx={{ my: '0.5rem' }}
          required
          id="test-name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => handleNameUpdate(e.target.value)}
        />
        <TextField
          sx={{ my: '1rem' }}
          id="test-script"
          label="Enter Json"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={9}
          value={jsonInput}
          onChange={(e) => handleInput(e.target.value)}
          error={responseMessage !== ''}
          helperText={responseMessage}
        />
        {jsonInput.length != 0 && (
          <span style={{ fontSize: '13px' }}>
            Note - schedules and alerting conditions specified in the imported JSON will not be
            automatically applied to the test. Apply them explicitly after creating the test
          </span>
        )}
      </Grid>
    </ConfirmDialog>
  );
};

export default TestImportForm;
