import { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import StepDetailsModalWrapper from '../StepDetailsModalWrapper';
import AddVariablesModalContent from './AddVariablesModalContent';
import ExtractVariablesTable from './ExtractVariablesTable';

const AddVariablesForm = ({ extractVariablesList, setExtractVariablesList }) => {
  const [openVariablesModal, setOpenVariablesModal] = useState(false);
  const [variableOBJ, setVariableOBJ] = useState({});
  const [editIndex, setEditIndex] = useState(-1);

  const handleVariable = (target) => {
    setVariableOBJ((variableOBJ) => {
      return {
        ...variableOBJ,
        source: target.name === 'variable-source' ? target.value : variableOBJ.source,
        sourcePath: target.name === 'source-path' ? target.value : variableOBJ.sourcePath,
        destinationVariableName:
          target.name === 'variable-name' ? target.value : variableOBJ.destinationVariableName
        // variableGlobal:
        //   target.checked && target.label === 'Global' ? target.checked : variableOBJ.variableGlobal
      };
    });
  };

  const setFormStateOnEdit = (index, extractedVariable) => {
    setEditIndex(index);
    setVariableOBJ(extractedVariable);
    setOpenVariablesModal(true);
  };

  const handleDeleteExtractVariable = (indexToBeDeleted) => {
    let temp = extractVariablesList.filter((_item, index) => index !== indexToBeDeleted);
    setExtractVariablesList(temp);
  };

  const handleAddVariable = () => {
    let temp = extractVariablesList === null ? [] : extractVariablesList.slice();
    // editing existing variable
    if (editIndex !== -1) {
      temp.splice(editIndex, 1, variableOBJ);
      setExtractVariablesList(temp);
    }
    // adding new variable
    else {
      setExtractVariablesList([...temp, variableOBJ]);
    }
    setOpenVariablesModal(false);
    setEditIndex(-1);
    setVariableOBJ({});
  };

  return (
    <>
      <Grid sx={{ display: 'flex', justifyContent: 'end', mb: '1rem' }}>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            setOpenVariablesModal(true);
          }}>
          Add Variable
          <AddIcon />
        </Button>
        {openVariablesModal && (
          <StepDetailsModalWrapper
            open={openVariablesModal}
            title="Extract Variables"
            edit={editIndex !== -1}
            disabled={
              !variableOBJ.source || !variableOBJ.sourcePath || !variableOBJ.destinationVariableName
            }
            handleSubmit={handleAddVariable}
            handleCancel={(e) => {
              e.preventDefault();
              setVariableOBJ({});
              setOpenVariablesModal(false);
              setEditIndex(-1);
            }}>
            <AddVariablesModalContent variableOBJ={variableOBJ} handleVariable={handleVariable} />
          </StepDetailsModalWrapper>
        )}
      </Grid>
      <ExtractVariablesTable
        extractVariablesList={extractVariablesList}
        setFormStateOnEdit={setFormStateOnEdit}
        handleDeleteExtractVariable={handleDeleteExtractVariable}
      />
    </>
  );
};

export default AddVariablesForm;
