import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allSuites: [],
  suites: [],
  suite: {
    id: '',
    name: '',
    description: ''
  }
};
export const suiteSlice = createSlice({
  name: 'suiteSlice',
  initialState: initialState,
  reducers: {
    updateSuiteList(state, action) {
      state.suites = action.payload;
    },
    updateAllSuiteList(state, action) {
      state.allSuites = action.payload;
    },
    updateSuite(state, action) {
      state.suite = action.payload;
    },
    resetSuite(state) {
      state.suite = initialState.suite;
    }
  }
});

export const suiteActions = suiteSlice.actions;
