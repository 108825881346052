/* eslint-disable */
import { useState } from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

const TestEnvironmentsForm = ({
  handleTestEnvironments,
  step,
  environments,
  selectedEnvironments
}) => {
  const [checkAll, setCheckAll] = useState(false);
  const [checkedOptionsCount, setCheckedOptionsCount] = useState(0);

  const handleChecked = (target) => {
    const value = target.value;
    const checked = target.checked;

    if (value === 'all') {
      setCheckAll(() => {
        return !checkAll;
      });

      handleTestEnvironments(checked, value);
    }

    if (value != 'all') {
      if (checked === true) {
        setCheckedOptionsCount(checkedOptionsCount + 1);
      } else {
        setCheckedOptionsCount(checkedOptionsCount - 1);
      }
      handleTestEnvironments(checked, value);
    }
  };

  return (
    <FormGroup hidden={step != 1} sx={{ my: '2rem' }}>
      <Box>
        {environments?.length !== 0 ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={checkAll}
                value="all"
                disabled={checkedOptionsCount > 0 ? true : false}
                onChange={(e) => handleChecked(e.target)}
              />
            }
            label="All"
          />
        ) : (
          <p>There are no environments associated with this test suite.</p>
        )}
        {environments.map((environment, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={checkAll || selectedEnvironments.includes(environment.name)}
                  value={environment.name}
                  disabled={checkAll ? true : false}
                  onChange={(e) => handleChecked(e.target)}
                />
              }
              label={environment.name}
            />
          );
        })}
      </Box>
    </FormGroup>
  );
};

export default TestEnvironmentsForm;
