import { Button, Grid, Tabs, Tab, Box, Typography, Pagination } from '@mui/material';
import CreateTeamModal from '../team/CreateTeamModal';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../store/slice/CommonSlice';
import {
  createTeam,
  fetchAllTeamsForUser,
  fetchAllTeams
} from '../../store/slice/team/TeamActions';
import {
  createSuite,
  fetchAllSuitesForUser,
  fetchAllSuites
} from '../../store/slice/suite/SuiteActions';
import { fetchAllProducts } from '../../store/slice/product/ProductActions';
import TeamCard from '../team/TeamCard';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { teamActions } from '../../store/slice/team/TeamSlice';
import TestSuiteCard from '../suites/TestSuiteCard';
import CreateTestSuiteModal from '../suites/CreateTestSuiteModal';
import axiosInstance from '../../services/axios';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function getTabDetails(index) {
  return {
    id: index,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function getSubTabDetails(index) {
  return {
    id: index,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const HomePage = () => {
  const dispatch = useDispatch();
  const [indicators, setIndicators] = useState([]);
  const teams = useSelector((state) => state.teamData.teams);
  const products = useSelector((state) => state.productData.products);
  const suites = useSelector((state) => state.suiteData.suites);
  const allSuites = useSelector((state) => state.suiteData.allSuites);
  const allTeams = useSelector((state) => state.teamData.allTeams);
  const [suitesChangeFlag, setSuitesChangeFlag] = useState(false);
  const [teamsChangeFlag, setTeamsChangeFlag] = useState(false);
  const [openCreateTeamModal, setOpenCreateTeamModal] = useState(false);
  const [openCreateTestSuiteModal, setOpenCreateTestSuiteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const onCreateTeamBtnClick = () => {
    setNestedValue(0);
    dispatch(teamActions.resetTeam());
    setOpenCreateTeamModal(true);
  };
  const onCreateTestSuiteBtnClick = () => {
    setNestedValue(0);
    setOpenCreateTestSuiteModal(true);
  };
  const team = {
    id: '',
    description: '',
    teamMembers: []
  };

  const onCreateTeamBtnClose = () => setOpenCreateTeamModal(false);
  const onCreateTestSuiteBtnClose = () => setOpenCreateTestSuiteModal(false);
  const [value, setValue] = useState(0);
  const [nestedValue, setNestedValue] = useState(0);

  const handleFetchAllSuites = () => {
    dispatch(fetchAllSuites(currentPage - 1));
  };
  const handleFetchAllTeams = () => {
    dispatch(fetchAllTeams(currentPage - 1));
  };

  const handleChange = (_event, newValue) => {
    setNestedValue(0);
    setValue(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (value === 0 && nestedValue === 0) {
      dispatch(fetchAllSuitesForUser(currentPage - 1));
    } else if (value === 0 && nestedValue === 1) {
      dispatch(fetchAllSuites(currentPage - 1));
    } else if (value === 1 && nestedValue === 1) {
      dispatch(fetchAllTeams(currentPage - 1));
    } else if (value === 1 && nestedValue === 0) {
      dispatch(fetchAllTeamsForUser(currentPage - 1));
    }
  }, [currentPage, nestedValue, value]);

  const handleNestedChange = (event, newValue) => {
    setCurrentPage(1);
    value === 0
      ? event.target.id === '1'
        ? (allSuites.length === 0 || suitesChangeFlag) && handleFetchAllSuites()
        : setSuitesChangeFlag(false)
      : event.target.id === '1'
      ? (allTeams.length === 0 || teamsChangeFlag) && handleFetchAllTeams()
      : setTeamsChangeFlag(false);
    setNestedValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchAllSuitesForUser(currentPage - 1));
    dispatch(fetchAllTeamsForUser(currentPage - 1));
    dispatch(fetchAllProducts());
  }, []);

  useEffect(() => {
    if (suites.length > 0) {
      suites.map(async (suite) => {
        await axiosInstance({
          method: 'get',
          url: `/v1/test_suites/${suite.id}/results/failed?checkCount=10`
        })
          .then((response) => {
            let flag = response.data > 0 ? true : false;
            let found = indicators.some((el) => el.id === suite.id);
            !found && setIndicators((prev) => [...prev, { id: suite.id, failedFlag: flag }]);
          })
          .catch((error) => {
            console.log('error', error);
          });
      });
    }
  }, [suites]);

  const onSaveTeam = (teamsData) => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(createTeam(teamsData)).then(
      () => {
        setTeamsChangeFlag(true);
        setOpenCreateTeamModal(false);
        dispatch(
          commonActions.showSuccessAlert(
            `Create team ${teamsData.id} request accepted successfully.`
          )
        );
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(fetchAllTeamsForUser(currentPage - 1));
        setValue(1);
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  const onSaveTestSuite = (testSuiteData) => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(createSuite(testSuiteData)).then(
      () => {
        setSuitesChangeFlag(true);
        setOpenCreateTestSuiteModal(false);
        dispatch(commonActions.showSuccessAlert(`Test Suite ${testSuiteData.name} created`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(fetchAllSuitesForUser(currentPage - 1));
        setValue(0);
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        onCreateTestSuiteBtnClose();
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Grid container>
      <Grid item xs={10.5}>
        <Stack direction="row-reverse">
          <Button
            variant="outlined"
            onClick={onCreateTestSuiteBtnClick}
            data-testid="button-create-suite">
            Create Test Suite
          </Button>
          <Button
            variant="outlined"
            onClick={onCreateTeamBtnClick}
            data-testid="button-create-team">
            Create Team
          </Button>
        </Stack>
      </Grid>
      <CreateTeamModal
        open={openCreateTeamModal}
        width="md"
        title="Create New Team in MTTS"
        handleSave={onSaveTeam}
        handleClose={onCreateTeamBtnClose}
        team={team}
      />
      <CreateTestSuiteModal
        open={openCreateTestSuiteModal}
        width="xs"
        title="Create New Test Suite"
        handleSave={onSaveTestSuite}
        handleClose={onCreateTestSuiteBtnClose}
        teams={teams?.teamSummaryDTOS}
        products={products}
        suite={{}}
      />
      <Box
        sx={{
          width: '85rem',
          margin: 'auto',
          position: 'relative',
          minHeight: '71vh',
          '& .css-19kzrtu': { p: 0 }
        }}>
        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="tab-panels">
            <Tab label="Suites" {...getTabDetails(0)} />
            <Tab label="MTTS Teams" {...getTabDetails(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Tabs value={nestedValue} onChange={handleNestedChange} aria-label="sub-tab-panels">
            <Tab label="My Suites" {...getSubTabDetails(0)} />
            <Tab label="All Suites" {...getSubTabDetails(1)} />
          </Tabs>
          <TabPanel value={nestedValue} index={0}>
            <TestSuiteCard
              suites={suites?.testSuiteSummaryDTOList}
              indicators={indicators}
              setSuitesChangeFlag={setSuitesChangeFlag}
            />
            {suites?.totalPages > 0 && (
              <Pagination
                count={suites?.totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ margin: '15px 0', display: 'flex', justifyContent: 'end' }}
              />
            )}
          </TabPanel>
          <TabPanel value={nestedValue} index={1}>
            <TestSuiteCard suites={allSuites?.testSuiteSummaryDTOList} />
            {allSuites?.totalPages > 0 && (
              <Pagination
                count={allSuites?.totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ margin: '15px 0', display: 'flex', justifyContent: 'end' }}
              />
            )}
          </TabPanel>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Tabs value={nestedValue} onChange={handleNestedChange} aria-label="sub-tab-panels-teams">
            <Tab label="My Teams" {...getSubTabDetails(0)} />
            <Tab label="All Teams" {...getSubTabDetails(1)} />
          </Tabs>
          <TabPanel value={nestedValue} index={0}>
            <TeamCard teams={teams?.teamSummaryDTOS} setTeamsChangeFlag={setTeamsChangeFlag} />
            {teams?.totalPages > 0 && (
              <Pagination
                count={teams?.totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ margin: '15px 0', display: 'flex', justifyContent: 'end' }}
              />
            )}
          </TabPanel>
          <TabPanel value={nestedValue} index={1}>
            <TeamCard teams={allTeams?.teamSummaryDTOS} />
            {allTeams?.totalPages > 0 && (
              <Pagination
                count={allTeams?.totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ margin: '15px 0', display: 'flex', justifyContent: 'end' }}
              />
            )}
          </TabPanel>
        </TabPanel>
      </Box>
    </Grid>
  );
};
export default HomePage;
