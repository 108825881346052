import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tests: [],
  browserTests: [],
  test: {},
  browserTest: {}
};
export const testSlice = createSlice({
  name: 'testSlice',
  initialState: initialState,
  reducers: {
    updateTestList(state, action) {
      state.tests = action.payload;
    },
    updateBrowserTestList(state, action) {
      state.browserTests = action.payload;
    },
    updateTest(state, action) {
      state.test = action.payload;
    },
    updateBrowserTest(state, action) {
      state.browserTest = action.payload;
    }
  }
});

export const testActions = testSlice.actions;
