import TextField from '@mui/material/TextField';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import ConfirmDialog from '../../components/modal/ConfirmDialog';

const CreateVariableModal = (props) => {
  const [variable, setVariable] = useState({ environmentId: props.environment.id });
  const [disableSave, setDisableSave] = useState(true);

  const handleSave = () => {
    props.handleSave(variable);
  };

  const handleClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (props.variable != undefined) {
      setVariable({
        id: props.variable.id,
        environmentId: props.environment.id,
        name: props.variable.name,
        description: props.variable.description,
        value: props.variable.value,
        secret: props.variable.secret
      });
    }
  }, [props.variable]);

  useEffect(() => {
    const disable = !variable.name || !variable.description || !variable.value;
    setDisableSave(disable);
  }, [variable]);

  return (
    <ConfirmDialog
      open={props.open}
      width={props.width}
      title={props.title}
      handleSave={handleSave}
      handleClose={handleClose}
      disableSave={disableSave}>
      <TextField
        margin="dense"
        data-testid="create-variable-name"
        label="Variable Name"
        required
        type="text"
        fullWidth
        variant="outlined"
        disabled={props.variable.name ? true : false}
        defaultValue={props.variable ? props.variable.name : ''}
        onChange={(e) => setVariable({ ...variable, name: e.target.value })}
      />
      <TextField
        margin="dense"
        data-testid="create-variable-desc"
        multiline
        required
        rows={2}
        label="Description"
        fullWidth
        variant="outlined"
        defaultValue={props.variable ? props.variable.description : ''}
        onChange={(e) => setVariable({ ...variable, description: e.target.value })}
        sx={{ marginTop: 2 }}
      />
      <TextField
        margin="dense"
        data-testid="create-variable-value"
        multiline
        required
        rows={2}
        label="Value"
        fullWidth
        variant="outlined"
        defaultValue={props.variable ? props.variable.value : ''}
        onChange={(e) => setVariable({ ...variable, value: e.target.value })}
        sx={{ marginTop: 2 }}
      />
      <Tooltip title="Save this variable data securely">
        <FormControlLabel
          control={
            <Switch
              defaultChecked={props.variable ? props.variable.secret : false}
              onChange={(e) => setVariable({ ...variable, secret: e.target.checked })}
            />
          }
          sx={{ marginTop: 2 }}
          label="Secret"
          labelPlacement="end"
        />
      </Tooltip>
    </ConfirmDialog>
  );
};
export default CreateVariableModal;
