import { Autocomplete, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../services/axios';
import { TEST_TYPES } from '../../../utils/TestTypes';

const TestDetailsForm = ({
  step,
  name,
  setName,
  description,
  setDescription,
  type,
  startingUrl,
  setStartingUrl,
  browserDeviceMappings,
  setBrowserDeviceMappings,
  setBrowserDeviceMappingChangeFlag
}) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedBrowser, setSelectedBrowser] = useState(null);
  const [browserDeviceMappingsList, setBrowserDeviceMappingsList] = useState({});

  const handleDeviceChange = (_, newValue) => {
    setSelectedDevice(newValue);
    setSelectedBrowser(null);
  };

  const handleAddBrowserDevicePair = (_, newValue) => {
    if (selectedDevice && newValue) {
      setBrowserDeviceMappingChangeFlag(true);
      setBrowserDeviceMappings([
        ...browserDeviceMappings,
        { deviceType: selectedDevice, browserType: newValue }
      ]);
      setSelectedDevice(null);
      setSelectedBrowser(null);
    }
  };

  const handleDeleteBrowserDevicePair = (index) => {
    setBrowserDeviceMappingChangeFlag(true);
    const updatedPairs = [...browserDeviceMappings];
    updatedPairs.splice(index, 1);
    setBrowserDeviceMappings(updatedPairs);
  };

  useEffect(() => {
    if (type === TEST_TYPES.BROWSER) {
      const getSupportedDeviceBrowserMappings = async () => {
        const fetchData = async () => {
          const response = await axiosInstance({
            method: 'get',
            url: '/v1/ui/device_browser_mappings'
          });
          const data = response.data;
          return data;
        };
        try {
          const browserDeviceMappings = await fetchData();
          setBrowserDeviceMappingsList(browserDeviceMappings);
        } catch (error) {
          console.log('error', error);
        }
      };
      getSupportedDeviceBrowserMappings();
    }
  }, []);

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ my: '2rem' }} hidden={step != 0}>
      <TextField
        sx={{ mb: '1rem' }}
        required
        id="test-name"
        label="Name"
        type="text"
        fullWidth
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        sx={{ mb: '1rem' }}
        required
        id="test-description"
        label="Description"
        type="text"
        fullWidth
        variant="outlined"
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      {type === TEST_TYPES.BROWSER && (
        <>
          <TextField
            sx={{ mb: '1rem' }}
            required
            id="test-base-url"
            label="Starting URL"
            type="text"
            fullWidth
            variant="outlined"
            value={startingUrl}
            onChange={(e) => setStartingUrl(e.target.value)}
          />
          <Autocomplete
            sx={{ mb: '1rem' }}
            data-testid="select-device-combo-box"
            options={Object.keys(browserDeviceMappingsList)}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => <TextField {...params} label="Add Device" />}
            value={selectedDevice}
            onChange={handleDeviceChange}
          />

          {selectedDevice && (
            <Autocomplete
              sx={{ mb: '1rem' }}
              data-testid="select-browser-combo-box"
              options={browserDeviceMappingsList[selectedDevice] || []}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => <TextField {...params} label="Select Browser" />}
              value={selectedBrowser}
              onChange={handleAddBrowserDevicePair}
            />
          )}
          {browserDeviceMappings?.map((pair, index) => (
            <Chip
              sx={{ mr: 1 }}
              size="small"
              key={index}
              label={pair.deviceType + ' - ' + pair.browserType}
              onDelete={() => handleDeleteBrowserDevicePair(index)}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default TestDetailsForm;
