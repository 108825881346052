import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';

const AddVariablesModalContent = ({ variableOBJ, handleVariable }) => {
  return (
    <Box component="form" noValidate autoComplete="off" sx={{ my: '2rem' }}>
      <FormControl sx={{ minWidth: 550, mb: '1rem' }}>
        <InputLabel>Source</InputLabel>
        <Select
          required
          label="Source"
          defaultValue=""
          name="variable-source"
          value={variableOBJ.source}
          onChange={(e) => handleVariable(e.target)}>
          <MenuItem value={'body'}>Body</MenuItem>
          <MenuItem value={'header'}>Header</MenuItem>
        </Select>
      </FormControl>
      <TextField
        required
        sx={{ mb: '1rem' }}
        margin="dense"
        id="field-label"
        label="Source Path"
        type="text"
        fullWidth
        variant="outlined"
        defaultValue=""
        name="source-path"
        value={variableOBJ.sourcePath}
        onChange={(e) => handleVariable(e.target)}
      />
      <TextField
        required
        sx={{ mb: '1rem' }}
        margin="dense"
        id="variable-name"
        label="Variable Name"
        type="text"
        fullWidth
        variant="outlined"
        defaultValue=""
        name="variable-name"
        value={variableOBJ.destinationVariableName}
        onChange={(e) => handleVariable(e.target)}
      />
      <Tooltip title={'Feature coming soon...'}>
        <FormControlLabel
          control={<Switch disabled onChange={(e) => handleVariable(e.target)} />}
          label="Global"
        />
      </Tooltip>
    </Box>
  );
};

export default AddVariablesModalContent;
