import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { commonActions } from '../../store/slice/CommonSlice';
import axiosInstance from '../../services/axios';
import { fetchAllEnvironmentsInTestSuite } from '../../store/slice/environment/EnvironmentActions';
import { Autocomplete } from '@mui/material';

const EnvironmentImportForm = (props) => {
  const [jsonInput, setJsonInput] = useState('');
  const { suiteId } = useParams();
  const dispatch = useDispatch();
  const [responseMessage, setResponseMessage] = useState('');
  const [envNameOptions, setEnvNameOptions] = useState([]);
  const [environmentName, setEnvironmentName] = useState('');
  const [containSecret, setContainSecret] = useState(false);
  const [invalidJson, setInvalidJson] = useState(true);

  const handleClose = () => {
    props.setOpenImportEnvironmentModal(false);
    setResponseMessage('');
    setJsonInput('');
    setContainSecret(false);
  };

  const handleNameUpdate = (name) => {
    try {
      setEnvironmentName(name);
      const environmentData = JSON.parse(jsonInput);
      environmentData.name = name;
      environmentData.testSuiteId = suiteId;
      environmentData.alertWebhook = '';
      setJsonInput(JSON.stringify(environmentData, null, 2));
    } catch (error) {
      console.log('failed to parse JSON');
    }
  };

  const handleInput = (json) => {
    setResponseMessage('');
    setJsonInput(json);
    setInvalidJson(false);
    if (json.trim() !== '') {
      try {
        const environmentData = JSON.parse(json);
        if (environmentName !== '') {
          environmentData.name = environmentName;
        }
        environmentData.testSuiteId = suiteId;
        environmentData.alertWebhook = '';
        setJsonInput(JSON.stringify(environmentData, null, 2));
        const variableData = environmentData.variables;
        const containsSecret = variableData.some((variable) => variable.secret);
        if (containsSecret) {
          setContainSecret(true);
        }
      } catch (error) {
        console.log('failed to parse JSON');
        setInvalidJson(true);
        setResponseMessage('Please enter valid Environment JSON');
      }
    }
  };

  useEffect(async () => {
    await axiosInstance({
      method: 'get',
      url: '/v1/ui/environments'
    })
      .then((response) => {
        setEnvNameOptions(response.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const handleLoad = async () => {
    try {
      const environmentData = JSON.parse(jsonInput);
      environmentData.name = environmentName;
      environmentData.testSuiteId = suiteId;
      environmentData.alertWebhook = '';
      const variableData = environmentData.variables;
      const containsSecret = variableData.some((variable) => variable.secret);
      if (containsSecret) {
        setContainSecret(true);
      }
      const updatedJson = JSON.stringify(environmentData);
      await axiosInstance({
        method: 'post',
        url: `/v1/test_suites/${suiteId}/environments/import`,
        data: updatedJson
      })
        .then(() => {
          setJsonInput('');
          props.setOpenImportEnvironmentModal(false);
          dispatch(commonActions.showSuccessAlert(`Environment ${environmentData.name} Added`));
          dispatch(commonActions.showLoadingIcon(false));
          dispatch(fetchAllEnvironmentsInTestSuite(suiteId));
          setResponseMessage('');
          setContainSecret(false);
        })
        .catch((error) => {
          setJsonInput('');
          props.setOpenImportEnvironmentModal(false);
          dispatch(commonActions.showLoadingIcon(false));
          dispatch(commonActions.showErrorAlert(error.response.data.message));
          setResponseMessage('');
          setContainSecret(false);
        });
    } catch (error) {
      console.log('failed to parse JSON');
      setInvalidJson(true);
      setResponseMessage('Please enter valid Environment JSON');
    }
  };
  return (
    <ConfirmDialog
      open={props.open}
      width={props.width}
      title={props.title}
      saveText="Import"
      handleSave={handleLoad}
      handleClose={handleClose}
      disableSave={environmentName.length == 0 || jsonInput.length == 0 || invalidJson}>
      <Grid container>
        <Autocomplete
          required
          margin="dense"
          data-testid="create-environment-name"
          disabled={props.environment}
          options={envNameOptions}
          isOptionEqualToValue={(option, value) => option === value}
          renderInput={(params) => <TextField {...params} label="Select Environment Name" />}
          defaultValue={props.environment ? props.environment.name : ''}
          onChange={(event, newValue) => handleNameUpdate(newValue)}
          sx={{ my: '0.5rem', width: '400px' }}
        />
        <TextField
          sx={{ my: '1rem' }}
          id="environment-json"
          label="Enter Json"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={9}
          value={jsonInput}
          onChange={(e) => handleInput(e.target.value)}
          error={responseMessage !== ''}
          helperText={responseMessage}
        />
        {containSecret && (
          <span style={{ fontSize: '13px' }}>
            Caution: This environment includes secret variables. Kindly update their values
            accordingly.
          </span>
        )}
      </Grid>
    </ConfirmDialog>
  );
};

export default EnvironmentImportForm;
