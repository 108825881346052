import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const CustomDataGrid = styled(DataGrid)({
  boxShadow: 0,
  width: '90%',
  margin: 'auto',
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#F7F7F7'
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#141414'
  },
  '.MuiDataGrid-cell:focus': {
    outline: 'none'
  },
  '.MuiDataGrid-row': {
    backgroundColor: 'white'
  },
  '.MuiDataGrid-row:hover': {
    backgroundColor: 'white'
  },
  '.MuiDataGrid-footerContainer': {
    backgroundColor: 'white'
  }
});

export default CustomDataGrid;
