import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';
import { TEST_TYPES } from '../../../utils/TestTypes';

const TestFrequencyForm = ({ step, handleTestSchedules, schedules, type }) => (
  <Stack spacing={2} hidden={step != 2} sx={{ my: '2rem' }}>
    {schedules.length === 0 && 'Select an environment to create a schedule.'}
    {schedules.map((envSchedule, index) => {
      return (
        <Grid key={index} sx={{ display: 'flex' }}>
          <FormControl hidden={envSchedule?.cron} sx={{ mr: '1rem', minWidth: 400 }}>
            <InputLabel>{envSchedule.environmentName} Environment</InputLabel>
            <Select
              label={`${envSchedule.environmentName} Environment`}
              value={envSchedule.duration || ''}
              name={`${envSchedule.environmentName}.duration`}
              onChange={(e) => handleTestSchedules(e.target)}>
              {type === TEST_TYPES.API && <MenuItem value={60}>every 1 minute</MenuItem>}
              {type === TEST_TYPES.API && <MenuItem value={300}>every 5 minutes</MenuItem>}
              <MenuItem value={900}>every 15 minutes</MenuItem>
              <MenuItem value={1800}>every 30 minutes</MenuItem>
              <MenuItem value={3600}>every hour</MenuItem>
            </Select>
          </FormControl>
          <Grid hidden={!envSchedule?.cron} style={{ width: '50ch', marginRight: '1rem' }}>
            <TextField
              defaultValue={envSchedule.cronExpression || ''}
              margin="dense"
              type="text"
              fullWidth
              variant="outlined"
              label={`${envSchedule.environmentName} Environment`}
              id={`${envSchedule.environmentName}-cron`}
              onBlur={(e) => handleTestSchedules(e.target)}
            />
          </Grid>
          <Tooltip title="Feature coming soon!">
            <FormControlLabel
              control={
                <Switch
                  disabled
                  defaultChecked={envSchedule.cron}
                  onChange={(e) => handleTestSchedules(e.target)}
                />
              }
              label="CRON"
            />
          </Tooltip>
        </Grid>
      );
    })}
  </Stack>
);

export default TestFrequencyForm;
