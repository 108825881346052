import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Tooltip } from '@mui/material';

const DefineStepForm = ({
  stepName,
  setStepName,
  stepDescription,
  setStepDescription,
  stepMethod,
  setStepMethod,
  stepURL,
  setStepURL,
  pauseDuration,
  setPauseDuration
}) => {
  const min = 0;
  const max = 10;
  const getAllowedPauseDuration = (value) => {
    let allowedValue = parseInt(value, 10);
    if (allowedValue > max) allowedValue = max;
    if (allowedValue < min) allowedValue = min;
    return allowedValue;
  };
  return (
    <Grid>
      <TextField
        sx={{ mb: '0.5rem' }}
        required={true}
        margin="dense"
        id="test-step-name"
        label="Step Name"
        type="text"
        fullWidth
        variant="outlined"
        value={stepName}
        onChange={(e) => setStepName(e.target.value)}
      />
      <TextField
        sx={{ mb: '0.5rem' }}
        margin="dense"
        id="test-step-description"
        label="Step Description"
        type="text"
        fullWidth
        variant="outlined"
        multiline
        rows={2}
        value={stepDescription}
        onChange={(e) => setStepDescription(e.target.value)}
      />
      <Grid sx={{ display: 'flex', alignItems: 'baseline', mb: '0.5rem' }}>
        <FormControl name="url" sx={{ mr: '.5rem', minWidth: 110 }}>
          <Select value={stepMethod} onChange={(e) => setStepMethod(e.target.value)}>
            <MenuItem value={'GET'}>GET</MenuItem>
            <MenuItem value={'POST'}>POST</MenuItem>
            <MenuItem value={'PUT'}>PUT</MenuItem>
            <MenuItem value={'PATCH'}>PATCH</MenuItem>
            <MenuItem value={'DELETE'}>DELETE</MenuItem>
          </Select>
        </FormControl>
        <TextField
          required
          fullWidth
          label="URL"
          margin="dense"
          id="test-step-url"
          type="text"
          variant="outlined"
          value={stepURL}
          onChange={(e) => setStepURL(e.target.value)}
        />
      </Grid>
      <Tooltip title="Maximum allowed pause duration is 10 seconds!">
        <TextField
          sx={{ mb: '0.5rem' }}
          margin="dense"
          id="pause-duration"
          label="Pause Duration"
          type="number"
          inputProps={{ min, max }}
          fullWidth
          variant="outlined"
          value={pauseDuration}
          onChange={(e) => {
            setPauseDuration(getAllowedPauseDuration(e.target.value));
          }}
        />
      </Tooltip>
    </Grid>
  );
};

export default DefineStepForm;
